import { useState, Children, useEffect } from 'react'

export default function DragArea({
    children,
    id = 'default',
    onNewIdPosition,
}) {
    const childItems = Children.toArray(children).map((child, i) => ({
        id: `draggable-${id}-${i.toString()}`,
        child,
    }))

    const count = childItems.length

    const positions = Array.from({ length: count }, (_, i) => ({
        id: `dropzone-${id}-${i + 1}`,
    }))

    const [mappings, setMappings] = useState(
        positions.reduce((acc, pos, i) => {
            acc[pos.id] = childItems[i].id
            return acc
        }, {}),
    )

    const [draggedChild, setDraggedChild] = useState(null)
    const [draggedOverPosId, setDraggedOverPosId] = useState(null)

    useEffect(
        function () {
            setMappings(
                positions.reduce((acc, pos, i) => {
                    acc[pos.id] = childItems[i].id
                    return acc
                }, {}),
            )
        },
        [childItems.map((item) => item.child.key).join(',')],
    )

    function handleDragStart(e) {
        e.dataTransfer.effectAllowed = 'move'
        setDraggedChild(childItems.find((item) => item.id === e.target.id))
    }

    function handleDragOver(e) {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'move'
        // Get the dropzone / target
        setDraggedOverPosId(e.target?.id || null)
    }

    function handleDragDrop(e) {
        e.preventDefault()
        const key = childItems.find(
            (item) => item.child.key === draggedChild.child.key,
        ).child.props.dragid
        const newPositionId = e.target.id
        const newPositionIndex = positions.findIndex(
            (pos) => pos.id === newPositionId,
        )
        onNewIdPosition(key, newPositionIndex)

        setDraggedChild(null)
        setDraggedOverPosId(null)
    }

    return (
        <div className="drag-area">
            {positions.map((pos) => {
                const itemId = mappings[pos.id]
                const item =
                    itemId && childItems.find((item) => item.id === itemId)
                return (
                    <div
                        data-dropzone
                        key={pos.id}
                        id={pos.id}
                        onDrop={handleDragDrop}
                        onDragOver={handleDragOver}
                        className={
                            draggedOverPosId === pos.id ? 'dragged-over' : ''
                        }
                    >
                        {item ? (
                            <div
                                style={{
                                    pointerEvents:
                                        draggedChild &&
                                        draggedChild?.id !== item.id
                                            ? 'none'
                                            : 'auto',
                                }}
                                id={item.id}
                                draggable
                                onDragStart={handleDragStart}
                            >
                                {item.child}
                            </div>
                        ) : null}
                    </div>
                )
            })}
        </div>
    )
}
