import AddItemsInput from './AddItemsInput'
import CheckboxInput from './CheckboxInput'
import DriveFilesInput from './DriveFilesInput'
import { default as BrowserFileInput } from './FileInput'
import HtmlInput from './HtmlInput'
import InputWrapper from './InputWrapper'
import LiveSearch from './LiveSearch'
import LiveSearchAddItemsInput from './LiveSearchAddItemsInput'
import LiveSearchInput from './LiveSearchInput'
import PasswordInput from './PasswordInput'
import ResourceDropdownInput from './ResourceDropdownInput'
import ResourceListInput from './ResourceListInput'
import SelectInput from './SelectInput'
import SwitchInput from './SwitchInput'
import TextInput from './TextInput'

export default function Input({
    type = 'text',
    value,
    onChange,
    id,
    ...input
}) {
    let inputHtml = null

    const finalId = id || `data-form-input-${input.inputKey}`

    switch (type) {
        case 'resourceList':
            inputHtml = (
                <ResourceListInput
                    {...input}
                    flushSpaces
                    loadMoreButton
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'liveSearchAddItems':
            inputHtml = (
                <LiveSearchAddItemsInput
                    {...input}
                    loadMoreButton
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'liveSearch':
            inputHtml = (
                <LiveSearchInput {...input} value={value} onChange={onChange} />
            )
            break
        case 'resourceDropdown':
            inputHtml = (
                <ResourceDropdownInput
                    {...input}
                    id={finalId}
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'addItems':
            inputHtml = (
                <AddItemsInput
                    {...input}
                    id={finalId}
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'file':
            inputHtml = (
                <BrowserFileInput {...input} id={finalId} setFiles={onChange} />
            )
            break
        case 'driveFiles':
            inputHtml = (
                <DriveFilesInput {...input} value={value} onChange={onChange} />
            )
            break

        case 'checkbox':
            inputHtml = (
                <CheckboxInput
                    {...input}
                    id={finalId}
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'switch':
            inputHtml = (
                <SwitchInput
                    {...input}
                    id={finalId}
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'html':
            inputHtml = (
                <HtmlInput
                    {...input}
                    id={finalId}
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'select':
            inputHtml = (
                <SelectInput
                    {...input}
                    id={finalId}
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'password':
            inputHtml = (
                <PasswordInput
                    {...input}
                    id={finalId}
                    value={value}
                    onChange={onChange}
                />
            )
            break
        case 'jsx':
            inputHtml = input.render(value)
            break
        default:
            inputHtml = (
                <TextInput
                    {...input}
                    type={type}
                    id={finalId}
                    value={value}
                    onChange={onChange}
                />
            )
    }

    return (
        <InputWrapper
            inputId={finalId}
            label={type !== 'checkbox' ? input.label : ''}
            required={input.required}
            hideRequired={input.hideRequired}
            labelButton={input.labelButton}
            infoTop={input.infoTop}
            infoBottom={input.infoBottom}
            getPreviewText={input.getPreviewText}
            value={value}
        >
            {inputHtml}
        </InputWrapper>
    )
}
