import { Link } from 'react-router-dom'
import Spinner from './Spinner'
import Tag from './Tag'

function Button({
    gradient,
    small,
    fullWidth,
    tiny,
    destructive,
    white,
    dark,
    gold,
    outline,
    underline,
    inline,
    link,
    alignRight,
    card,
    className,
    info,
    href,
    cardTags,
    text,
    download,
    newPage,
    icon,
    disabled,
    onClick,
    type,
    isLoading,
    iconRight,
    title,
    hideText,
    plain,
    iconStyle,
    iconHasBadge,
}) {
    const noCallback = () => {}

    let finalClassName = 'button'
    // if (href) className += ' button-link'
    if (small) finalClassName += ' button-small'
    if (fullWidth) finalClassName += ' button-full-width'
    if (tiny) finalClassName += ' button-tiny'
    if (destructive) finalClassName += ' button-alert'
    if (white) finalClassName += ' button-white'
    if (dark) finalClassName += ' button-dark'
    if (gold) finalClassName += ' button-gold'
    if (outline) finalClassName += ' button-outline'
    if (inline) finalClassName += ' button-inline'
    if (link) finalClassName += ' button-link'
    if (inline) finalClassName += ' button-link-inline'
    if (alignRight) finalClassName += ' button-small-align-right'
    if (card) finalClassName += ' button-card'
    if (className) finalClassName += ` ${className}`
    if (info) finalClassName += ' button-has-info'
    if (underline) finalClassName += ' button-underline'
    if (underline === false) finalClassName += ' button-no-underline'
    if (gradient) finalClassName += ' button-gradient'
    if (iconRight) finalClassName += ' button-icon-right'
    if (plain) finalClassName += ' button-plain'
    if (!text) finalClassName += ' button-no-text'
    if (hideText) finalClassName += ' button-hide-text'
    if (isLoading) finalClassName += ' button-loading'

    const cartTagsHtml =
        card && cardTags?.length ? (
            <div className="card-tags">
                {cardTags?.map((tag) => (
                    <Tag key={tag.text} outline={Boolean(tag.outline)}>
                        {tag.text}
                    </Tag>
                ))}
            </div>
        ) : null

    let iconHtml = null
    if (typeof icon === 'string') {
        iconHtml = iconHasBadge ? (
            <span className="icon-with-badge-wrapper">
                <div className="icon-badge"></div>
                <img
                    className="icon"
                    src={icon}
                    width="16"
                    height="16"
                    alt=""
                    style={iconStyle || {}}
                />
            </span>
        ) : (
            <img
                className="icon"
                src={icon}
                width="16"
                height="16"
                alt=""
                style={iconStyle || {}}
            />
        )
    } else if (icon) {
        iconHtml = (
            <span className="icon" style={iconStyle || {}}>
                {icon}
            </span>
        )
    }

    if (href && href.startsWith('http')) {
        return (
            <>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                    href={href}
                    className={finalClassName}
                    download={download || undefined}
                    target={download || newPage ? '_blank' : undefined}
                    title={title}
                >
                    {!hideText && text}

                    {cartTagsHtml}
                </a>
                {info ? <small className="button-info">{info}</small> : null}
            </>
        )
    } else if (href) {
        return (
            <>
                <Link
                    disabled={isLoading || disabled}
                    className={finalClassName}
                    to={href}
                    target={download || newPage ? '_blank' : undefined}
                    title={title}
                >
                    {iconHtml}
                    {!hideText && text}

                    {cartTagsHtml}
                </Link>
                {info ? <small className="button-info">{info}</small> : null}
            </>
        )
    } else {
        return (
            <>
                <button
                    disabled={isLoading || disabled}
                    className={finalClassName}
                    onClick={onClick || noCallback}
                    type={type || 'button'}
                    title={title}
                >
                    {iconHtml}
                    <div>
                        {!hideText && text}
                        {cartTagsHtml}
                    </div>
                    {isLoading && <Spinner />}
                </button>
                {info ? <small className="button-info">{info}</small> : null}
            </>
        )
    }
}

export default Button
