import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'
import MainButton from '../../../components/admin/MainButton'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import Button from '../../../components/common/Button'
import useToast from '../../../common/hooks/useToast'
import InlineStack from '../../../components/common/InlineStack'
import { ICONS } from '../../../common/constants'
import useModal from '../../../common/hooks/useModal'
import InputModalContent from '../../../components/common/InputModalContent'
import useData from '../../../common/hooks/useData'
import Break from '../../../components/common/Break'
import useHashNav from '../../../common/hooks/useHashNav'
import ActionsDropdown from '../../../components/common/ActionsDropdown'

function Glossary({ items }) {
    const availableLetters = [
        ...new Set(items.map((item) => item.title[0].toUpperCase())),
    ].sort()

    const location = useLocation()

    useEffect(() => {
        if (!location.hash) return
        const removeHash = () => {
            window.history.replaceState(null, null, ' ')
        }
        setTimeout(removeHash, 0)
    }, [location.hash])

    const groups = items.reduce((acc, item) => {
        const letter = item.title[0].toUpperCase()
        if (!acc[letter]) {
            acc[letter] = []
        }
        acc[letter].push(item)
        return acc
    }, {})

    const sortedLetters = Object.keys(groups).sort()

    return (
        <>
            <div className="glossary">
                <div>
                    {sortedLetters.map((letter) => (
                        <div key={letter}>
                            <h3 id={`glossary-letter-${letter}`} key={letter}>
                                {letter}
                            </h3>
                            <div>
                                {groups[letter].map((item) => (
                                    <div
                                        key={item.title}
                                        className={`glossary-item-${letter}`}
                                    >
                                        <Link to={item.link}>{item.title}</Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="letter-index">
                    <ul>
                        {availableLetters.map((l) => (
                            <li key={l}>
                                <Link to={`#glossary-letter-${l}`}>{l}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

function Page() {
    const { slug, categorySlug } = useParams()
    const auth = useAuth()

    const [page, setPage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [exportLoading, setExportLoading] = useState(false)
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const [filterTags, setFilterTags] = useState([])
    const location = useLocation()
    const [hash, setHash] = useHashNav()

    useEffect(() => {
        async function loadPrivatePage() {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/pages/private/${slug}`,
            )
            setLoading(false)
            if (error) {
                setError(error)
                return
            }
            setPage(responseData)
        }

        async function loadPage() {
            setLoading(true)
            const { responseData, error, status } = await fetchAPI(
                `/v1/pages/${slug}`,
            )
            setLoading(false)
            if (error && status === 403) {
                if (!auth.user) {
                    setError(
                        <>
                            Please&nbsp;
                            <Link
                                to={'/login'}
                                state={{
                                    redirectUrl: `/pages/${page?.parentPage ? `${page.parentPage.slug}/` : ''}${slug}`,
                                }}
                            >
                                login
                            </Link>
                            &nbsp;or&nbsp;
                            <Link to={'/register'}>register</Link>
                            &nbsp;to access this page
                        </>,
                    )
                    return
                } else {
                    loadPrivatePage()
                }
            } else if (error) {
                setError(error)
                return
            }
            setPage(responseData)
        }

        loadPage()
    }, [categorySlug, slug])

    useEffect(
        function () {
            if (!page || hash === `#${page.title}`) {
                return
            }
            setHash(`${page.title}`)
        },
        [page?.title],
    )

    async function onExportGroupXlsx() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?parentPageId=${page.id}${
                // Multible tags[] as query params
                filterTags.length > 0
                    ? `&tags[]=${filterTags.join('&tags[]=')}`
                    : ''
            }&format=xlsx`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const arrayBuffer = base64ToArrayBuffer(responseData.xlsxStr)

        // Create a Blob from the ArrayBuffer
        const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        // Create a URL for the Blob and trigger the download
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'pages.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function onExportGroupCsv() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?parentPageId=${page.id}${
                // Multible tags[] as query params
                filterTags.length > 0
                    ? `&tags[]=${filterTags.join('&tags[]=')}`
                    : ''
            }&format=csv`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const { csvStr } = responseData

        const blob = new Blob([csvStr], { type: 'text/csv' })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'pages.csv'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function onExportPdf() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?pageId=${page.id}&format=pdf`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const { pdfStr } = responseData

        const arrayBuffer = base64ToArrayBuffer(pdfStr)

        const blob = new Blob([arrayBuffer], {
            type: 'application/pdf',
        })

        const url = window.URL.createObjectURL(blob)
        console.log({ pdfStr })
        const a = document.createElement('a')
        a.href = url
        a.download = 'page.pdf'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    function base64ToArrayBuffer(base64) {
        const binaryString = atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        return bytes.buffer
    }

    async function openTagsModal() {
        setModal(
            <InputModalContent
                defaultValue={filterTags}
                items={[
                    ...new Set(page.publishedChildPages.flatMap((p) => p.tags)),
                ]}
                label="Tag"
                type="liveSearchAddItems"
                confirmText="OK"
                onCancel={() => {
                    setModal(null)
                }}
                onConfirm={async (data) => {
                    setFilterTags(data.items)
                    setModal(null)
                }}
            />,
            'Filter by tags',
            'modal-full',
        )
    }

    if (loading) {
        return <Spinner />
    }

    if (error) {
        return (
            <SectionContainer>
                <ErrorMessage>{error}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!page) return null

    return (
        <SectionContainer>
            <InlineStack gap={'sm'} justifyEnd>
                {
                    page.pageType === 'glossary' && (
                        <Button
                            text="Filter"
                            hideText
                            icon={ICONS.FILTER_WHITE}
                            iconHasBadge={filterTags.length > 0}
                            plain
                            // outline
                            onClick={openTagsModal}
                            small
                        />
                    )
                    // <Button
                    //     text="Export"
                    //     small
                    //     outline
                    //     onClick={onExportGroup}
                    //     isLoading={exportLoading}
                    // />
                }
                <ActionsDropdown
                    actions={[
                        {
                            title: 'Export XLSX',
                            onClick: onExportGroupXlsx,
                            hide:
                                (!auth.isAdmin && !auth.isAssistant) ||
                                page.pageType !== 'glossary',
                        },
                        {
                            title: 'Export CSV',
                            onClick: onExportGroupCsv,
                            hide:
                                (!auth.isAdmin && !auth.isAssistant) ||
                                page.pageType !== 'glossary',
                        },
                        {
                            title: 'Export PDF',
                            onClick: onExportPdf,
                            hide: !auth.isAdmin && !auth.isAssistant,
                        },
                    ]}
                />
            </InlineStack>
            {page.publishedParentPage && (
                <span className="text-subdued">
                    <Link to={`/pages/${page.publishedParentPage.slug}`}>
                        {page.publishedParentPage.title}
                    </Link>
                    &nbsp;/&nbsp;
                    <Link to={`/pages/${page.slug}`}>{page.title}</Link>
                    <Break />
                </span>
            )}
            {/* {page.title ? (
                <h1>
                    {`${page.publishedParentPage?.title ? `${page.publishedParentPage?.title}: ` : ''}${page.title}`}
                </h1>
            ) : null} */}
            {page.content && !page.isHtml ? <pre>{page.content}</pre> : null}
            {page.cssCode && page.isHtml ? (
                <style
                    dangerouslySetInnerHTML={{
                        __html: page.cssCode
                            .replace(/&lt;/g, '<')
                            .replace(/&gt;/g, '>')
                            .replace(/“/g, '"')
                            .replace(/”/g, '"'),
                    }}
                ></style>
            ) : null}
            {page.content && page.isHtml ? (
                <div
                    className={`page-${page.id}`}
                    dangerouslySetInnerHTML={{
                        __html: page.content
                            .replace(/&lt;/g, '<')
                            .replace(/&gt;/g, '>')
                            .replace(/“/g, '"')
                            .replace(/”/g, '"'),
                    }}
                ></div>
            ) : //   processPageContent(page.content)
            null}
            {page.pageType === 'glossary' && (
                <Glossary
                    items={(page.publishedChildPages || [])
                        .map((p) => ({
                            title: p.title,
                            link: `/pages/${page.slug}/${p.slug}`,
                            tags: p.tags,
                        }))
                        .filter(
                            (p) =>
                                filterTags.length === 0 ||
                                filterTags.every((tag) => p.tags.includes(tag)),
                        )}
                />
            )}
            {auth.isAdmin && (
                <MainButton
                    href={`/edit-pages/${page.id}`}
                    functionality="EDIT"
                />
            )}
        </SectionContainer>
    )
}

export default Page
