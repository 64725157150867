export const API_URL =
    process.env.REACT_APP_CONTEXT === 'NETLIFY_DEV'
        ? process.env.REACT_APP_BACKEND_URL_DEV
        : process.env.REACT_APP_BACKEND_URL

export const SOCIAL_SERVICE_NAMES = {
    // facebook: 'Facebook',
    facebook: 'Facebook & Instagram Business',
}

export const SOCIAL_PROPERTY_NAMES = {
    facebookPage: 'Facebook Page',
    facebookGroup: 'Facebook Group',
    facebookGroupAsPage: 'Facebook Group',
    instagramProfile: 'Instagram',
}

export const DEFAULT_TEAM_ROLES = [
    'assistants',
    'accountants',
    'collections',
    'late fees',
    'social editors',
    'client managers',
    'sales',
]

export const CONNECTED_ACCOUNT_SERVICE_NAMES = {
    facebook: 'Facebook',
}

export const CONNECTED_ACCOUNT_PROPERTY_TYPE_NAMES = {
    facebookPage: 'Page',
    igBusinessAccount: 'IG Account',
}

export const ALLOWED_NOTIF_TYPES = {
    admin: [
        'vendorAccountSubmission',
        'vendorProductCreation',
        'commentAdded',
        'orgMemberInvite',
    ],
    staff: ['commentAdded', 'orgMemberInvite'],
    user: ['commentAdded', 'orgMemberInvite'],
}

export const RETAIL_BUSINESS_CATEGORIES = {
    salon: {
        title: 'Salon',
        subcategories: {
            spa: {
                title: 'Spa',
            },
            hairSpa: {
                title: 'Hair Spa',
            },
            makeup: {
                title: 'Makeup',
            },
            other: {
                title: 'Other',
            },
        },
    },
    other: {
        title: 'Other',
        subcategories: {
            other: {
                title: 'Other',
            },
        },
    },
}

export const COLOR_VALUES = {
    green: '#00b894',
    red: '#d63031',
    white: '#ffffff',
}

export const TASK_STATUSES = {
    REVIEW: 'review', // accepted by staff, worked, req. review by admin
    REJECTED: 'rejected', // Admin rejected work, sends back for correction
    ACCEPTED: 'accepted', // Staff accepted task assigned to them
    OPEN: 'open', // Waiting acceptance by staff member
    DENIED: 'denied', // Staff denies task
    COMPLETE: 'complete', // Complete
    APPROVAL: 'approval', // Assistant created task and it needs acceptance by admin
    RECREATION: 'recreation', // Admin rejects new creation and provides details
    BLOCKED: 'blocked', // Staff marks it blocked because of some reason
}

export const TASK_STATUS_LABELS = {
    open: { label: 'Open', tagColor: 'primary' },
    accepted: { label: 'In progress', tagColor: 'primary' },
    review: { label: 'Internal review', tagColor: 'warning' },
    complete: { label: 'Complete', tagColor: 'success' },
    rejected: { label: 'Rejected', tagColor: 'alert' },
    denied: { label: 'User rejected', tagColor: 'alert' },
    approval: { label: 'New task', tagColor: 'warning' },
    recreation: { label: 'Creation rejected', tagColor: 'alert' },
    blocked: { label: 'Blocked', tagColor: 'alert' },
}

export const TASK_PRIORITY_LABELS = {
    high: { label: 'High', tagColor: 'alert' },
    medium: { label: 'Medium', tagColor: 'warning' },
    low: { label: 'Low', tagColor: 'primary' },
}

export const ICONS = {
    EDIT_WHITE: '/img/icons/edit-white.svg',
    EDIT_GRAY: '/img/icons/edit-gray.svg',
    EDIT_ACTIVE: '/img/icons/edit-active.svg',
    TRASH_WHITE: '/img/icons/trash-white.svg',
    TRASH_RED: '/img/icons/trash-red.svg',
    FOLDER_WHITE: '/img/icons/folder-white.svg',
    FOLDER_ACTIVE: '/img/icons/folder-active.svg',
    FOLDER_GRAY: '/img/icons/folder-gray.svg',
    CHEVRON_LEFT_WHITE: '/img/icons/chevron-left-white.svg',
    CHEVRON_RIGHT_GRAY: '/img/icons/chevron-right-gray.svg',
    CHEVRON_RIGHT_WHITE: '/img/icons/chevron-right-white.svg',
    CHEVRON_RIGHT_ACTIVE: '/img/icons/chevron-right-active.svg',
    SHOPPING_CART_WHITE: '/img/icons/shopping-cart-white.svg',
    SHOPPING_CART_ACTIVE: '/img/icons/shopping-cart-active.svg',
    MAIL_GRAY: '/img/icons/mail-gray.svg',
    MAIL_GRAY_2: '/img/icons/mail-gray-2.svg',
    MAIL_WHITE: '/img/icons/mail-white.svg',
    MAIL_ACTIVE: '/img/icons/mail-active.svg',
    SLASH_GRAY: '/img/icons/slash-gray.svg',
    SLASH_RED: '/img/icons/slash-red.svg',
    LOGIN_GRAY: '/img/icons/login-gray.svg',
    SETTINGS_GRAY: '/img/icons/settings-gray.svg',
    SETTINGS_WHITE: '/img/icons/settings-white.svg',
    LINK_GRAY: '/img/icons/link-gray.svg',
    LINK_ACTIVE: '/img/icons/link-active.svg',
    LINK_WHITE: '/img/icons/link-white.svg',
    PAUSE_GRAY: '/img/icons/pause-gray.svg',
    CHECK_GRAY: '/img/icons/check-gray.svg',
    CHECK_WHITE: '/img/icons/check-white.svg',
    EYE_GRAY: '/img/icons/eye-gray.svg',
    EYE_ACTIVE: '/img/icons/eye-active.svg',
    EYE_OFF_GRAY: '/img/icons/eye-off-gray.svg',
    CLIPBOARD_GRAY: '/img/icons/clipboard-gray.svg',
    CLIPBOARD_WHITE: '/img/icons/clipboard-white.svg',
    CLIPBOARD_ACTIVE: '/img/icons/clipboard-active.svg',
    DOWNLOAD_GRAY: '/img/icons/download-gray.svg',
    DOWNLOAD_ACTIVE: '/img/icons/download-active.svg',
    HOME_WHITE: '/img/icons/home-white.svg',
    PHONE_GRAY: '/img/icons/phone-gray.svg',
    PHONE_GRAY_2: '/img/icons/phone-gray-2.svg',
    PHONE_WHITE: '/img/icons/phone-white.svg',
    PHONE_ACTIVE: '/img/icons/phone-active.svg',
    SAVE_WHITE: '/img/icons/save-white.svg',
    LOCK_WHITE: '/img/icons/lock-white.svg',
    LOCK_ACTIVE: '/img/icons/lock-active.svg',
    LOCK_GRAY: '/img/icons/lock-gray.svg',
    PACKAGE_WHITE: '/img/icons/package-white.svg',
    PAYPAL_WHITE: '/img/icons/paypal-white.svg',
    BELL_WHITE: '/img/icons/bell-white.svg',
    BELL_ACTIVE: '/img/icons/bell-active.svg',
    HASH_GRAY: '/img/icons/hash-gray.svg',
    LABEL_WHITE: '/img/icons/label-white.svg',
    FILE_TEXT_ACTIVE: '/img/icons/file-text-active.svg',
    FEATHER_ACTIVE: '/img/icons/feather-active.svg',
    MONITOR_ACTIVE: '/img/icons/monitor-active.svg',
    HEART_ACTIVE: '/img/icons/heart-active.svg',
    SHARE_2_ACTIVE: '/img/icons/share-2-active.svg',
    CALENDAR_ACTIVE: '/img/icons/calendar-active.svg',
    CALENDAR_GRAY: '/img/icons/calendar-gray.svg',
    X_WHITE: '/img/icons/x-white.svg',
    X_ACTIVE: '/img/icons/x-active.svg',
    X_GRAY: '/img/icons/x-gray.svg',
    ALERT_ACTIVE: '/img/icons/alert-active.svg',
    ALERT_WHITE: '/img/icons/alert-white.svg',
    HELP_ACTIVE: '/img/icons/help-active.svg',
    HELP_WHITE: '/img/icons/help-white.svg',
    PLUS_WHITE: '/img/icons/plus-white.svg',
    PLUS_GRAY: '/img/icons/plus-gray.svg',
    PLUS_ACTIVE: '/img/icons/plus-active.svg',
    CLOCK_ACTIVE: '/img/icons/clock-active.svg',
    USER_ACTIVE: '/img/icons/user-active.svg',
    USER_GRAY: '/img/icons/user-gray.svg',
    USER_WHITE: '/img/icons/user-white.svg',
    LIST_ACTIVE: '/img/icons/list-active.svg',
    LIST_WHITE: '/img/icons/list-white.svg',
    SQUARE_ACTIVE: '/img/icons/square-active.svg',
    SQUARE_WHITE: '/img/icons/square-white.svg',
    ARROW_UP_A_Z_WHITE: '/img/icons/arrow-down-a-z-white.svg',
    ARROW_DOWN_Z_A_WHITE: '/img/icons/arrow-up-z-a-white.svg',
    GRID_WHITE: '/img/icons/grid-white.svg',
    GRID_ACTIVE: '/img/icons/grid-active.svg',
    SEARCH_BLACK: '/img/icons/search-black.svg',
    SEARCH_GRAY: '/img/icons/search-gray.svg',
    SEARCH_CURRENT: '/img/icons/search-current.svg',
    STAR_WHITE: '/img/icons/star-white.svg',
    STAR_FILLED_WHITE: '/img/icons/star-filled-white.svg',
    HEART_WHITE: '/img/icons/heart-white.svg',
    HEART_FILLED_WHITE: '/img/icons/heart-filled-white.svg',
    COPY_GRAY: '/img/icons/copy-gray.svg',
    COPY_ACTIVE: '/img/icons/copy-active.svg',
    COPY_WHITE: '/img/icons/copy-white.svg',
    MESSAGE_SQUARE_ACTIVE: '/img/icons/message-square-active.svg',
    MESSAGE_SQUARE_GRAY: '/img/icons/message-square-gray.svg',
    ARROW_DOWN_WHITE: '/img/icons/arrow-down-white.svg',
    BUG_ACTIVE: '/img/icons/bug-active.svg',
    BUG_GRAY: '/img/icons/bug-gray.svg',
    CLIENT_ACTIVE: '/img/icons/client-active.svg',
    CLIENT_GRAY: '/img/icons/client-gray.svg',
    ALIGN_LEFT_WHITE: '/img/icons/align-left-white.svg',
    ORDERED_LIST_WHITE: '/img/icons/ordered-list-white.svg',
    UNORDERED_LIST_WHITE: '/img/icons/unordered-list-white.svg',
    IMAGE_WHITE: '/img/icons/image-white.svg',
    FILTER_WHITE: '/img/icons/filter-white.svg',
}

export const HTML_CONTRACT_TYPES = {
    vendorRegistration: {
        title: 'Vendor Registration (Deprecated)',
    },
    vendorRegistrationIowa: {
        title: 'Vendor registration (Iowa)',
    },
    vendorRegistrationOnline: {
        title: 'Vendor registration (Online)',
    },
    vendorRegistrationIowaOnline: {
        title: 'Vendor registration (Iowa + Online)',
    },
    vendorConsignmentRegistration: {
        title: 'Consignment Registration',
    },
}

export const DRIVE_USAGES = {
    WORK_PROOF: 'workProof',
}

export const DRIVE_USAGE_SETTINGS = {
    workProof: {
        onlyUpload: false,
        accept: "'.jpg,.jpeg,.png,.pdf'",
        maxFileSize: 1024 * 1024 * 5,
        maxFiles: 2,
        mimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    },
    vendorProductSample: {
        onlyUpload: false,
        accept: "'.jpg,.jpeg,.png'",
        maxFileSize: 1024 * 1024 * 5,
        maxFiles: 5,
        mimeTypes: ['image/jpeg', 'image/png'],
    },
}

export const DRIVE_PARENTS_PER_USAGE = {
    clientTask: ['client-design', 'client-main', 'app-tasks'],
    teamTask: ['client-design', 'client-main', 'app-tasks'],
    social: ['client-design'],
    taskImageField: ['client-design', 'client-main', 'app-tasks'],
    taskFileField: ['client-design', 'client-main', 'app-tasks'],
    pdfForm: ['app-submitted-docs'],
    workProof: ['app-submitted-docs'],
}

export const PDF_FORM_DATA = {
    salesTaxExemptIowa: {
        name: 'Sales Tax Exempt Iowa',
    },
    salesTaxExemptTexas: {
        name: 'Sales Tax Exempt Texas',
    },
    workProof: {
        name: 'Proof of work',
    },
}

export const COMPANY_TYPES = [
    'Sole Proprietor',
    'LLC',
    'S Corporation',
    'C Corporation',
    'Non Profit',
    'Government',
]

export const TASK_ACTION_TEXT = {
    MARK_PAYOUT_PAID: 'Mark as paid',
    ACCEPT_COST_REQUEST: 'Accept and update Shopify',
    TEST: 'Click to test',
}

export const VENDOR_APPLY_QUESTIONS = {
    brandQuestion: {
        label: 'Tell us about your brand and the products you offer',
        info: 'How did you get started? What is your product?',
        required: true,
    },
    idealClientQuestion: {
        label: 'Who is your ideal client?',
        required: true,
    },
    freqProdQuestion: {
        label: 'How often do you have new products?',
        info: 'How often would you be sending us product? Seasonally? Quarterly?',
        required: true,
    },
    goalQuestion: {
        label: 'What is your goal in joining the showroom?',
    },
    grossQuestion: {
        label: 'How much does your brand make on average gross per year?',
        info: 'Based on the last 1-3 years',
        required: true,
    },
    costQuestion: {
        label: 'What is the average wholesale cost per product?',
        required: true,
    },
    siteQuestion: {
        label: 'Website',
        type: 'url',
        placeholder: 'https://www.yourwebsite.com',
    },
    fbPageQuestion: {
        label: 'Facebook Business Page',
        info: 'This is so we can help cross promote you and follow your page.',
        type: 'url',
        placeholder: 'https://www.facebook.com/yourbusiness',
    },
    fbGroupQuestion: {
        label: 'Facebook Group',
        type: 'url',
        placeholder: 'https://www.facebook.com/groups/yourgroup',
    },
    pinterestQuestion: {
        label: 'Pinterest',
        type: 'url',
        placeholder: 'https://www.pinterest.com/yourbusiness',
    },
    tikTokQuestion: {
        label: 'TikTok',
        type: 'url',
        placeholder: 'https://www.tiktok.com/@yourbusiness',
    },
    igQuestion: {
        label: 'Instagram',
        type: 'url',
        placeholder: 'https://www.instagram.com/yourbusiness',
    },
    yearQuestion: {
        label: 'Year your business was established',
        required: true,
    },
    hearQuestion: {
        label: 'How did you hear about the Showroom?',
    },
}

export const WEEK_DAYS = [
    {
        abbreviation: 'Sun',
        name: 'Sunday',
    },
    {
        abbreviation: 'Mon',
        name: 'Monday',
    },
    {
        abbreviation: 'Tue',
        name: 'Tuesday',
    },
    {
        abbreviation: 'Wed',
        name: 'Wednesday',
    },
    {
        abbreviation: 'Thu',
        name: 'Thursday',
    },
    {
        abbreviation: 'Fri',
        name: 'Friday',
    },
    {
        abbreviation: 'Sat',
        name: 'Saturday',
    },
]

export const MONTHS = [
    {
        abbreviation: 'Jan',
        name: 'January',
    },
    {
        abbreviation: 'Feb',
        name: 'February',
    },
    {
        abbreviation: 'Mar',
        name: 'March',
    },
    {
        abbreviation: 'Apr',
        name: 'April',
    },
    {
        abbreviation: 'May',
        name: 'May',
    },
    {
        abbreviation: 'Jun',
        name: 'June',
    },
    {
        abbreviation: 'Jul',
        name: 'July',
    },
    {
        abbreviation: 'Aug',
        name: 'August',
    },
    {
        abbreviation: 'Sep',
        name: 'September',
    },
    {
        abbreviation: 'Oct',
        name: 'October',
    },
    {
        abbreviation: 'Nov',
        name: 'November',
    },
    {
        abbreviation: 'Dec',
        name: 'December',
    },
]

export const COUNTRIES = [
    {
        value: 'AF',
        text: 'Afghanistan',
    },
    {
        value: 'AX',
        text: 'Åland Islands',
    },
    {
        value: 'AL',
        text: 'Albania',
    },
    {
        value: 'DZ',
        text: 'Algeria',
    },
    {
        value: 'AS',
        text: 'American Samoa',
    },
    {
        value: 'AD',
        text: 'Andorra',
    },
    {
        value: 'AO',
        text: 'Angola',
    },
    {
        value: 'AI',
        text: 'Anguilla',
    },
    {
        value: 'AQ',
        text: 'Antarctica',
    },
    {
        value: 'AG',
        text: 'Antigua and Barbuda',
    },
    {
        value: 'AR',
        text: 'Argentina',
    },
    {
        value: 'AM',
        text: 'Armenia',
    },
    {
        value: 'AW',
        text: 'Aruba',
    },
    {
        value: 'AU',
        text: 'Australia',
    },
    {
        value: 'AT',
        text: 'Austria',
    },
    {
        value: 'AZ',
        text: 'Azerbaijan',
    },
    {
        value: 'BS',
        text: 'Bahamas',
    },
    {
        value: 'BH',
        text: 'Bahrain',
    },
    {
        value: 'BD',
        text: 'Bangladesh',
    },
    {
        value: 'BB',
        text: 'Barbados',
    },
    {
        value: 'BY',
        text: 'Belarus',
    },
    {
        value: 'BE',
        text: 'Belgium',
    },
    {
        value: 'BZ',
        text: 'Belize',
    },
    {
        value: 'BJ',
        text: 'Benin',
    },
    {
        value: 'BM',
        text: 'Bermuda',
    },
    {
        value: 'BT',
        text: 'Bhutan',
    },
    {
        value: 'BO',
        text: 'Bolivia, Plurinational State of',
    },
    {
        value: 'BQ',
        text: 'Bonaire, Sint Eustatius and Saba',
    },
    {
        value: 'BA',
        text: 'Bosnia and Herzegovina',
    },
    {
        value: 'BW',
        text: 'Botswana',
    },
    {
        value: 'BV',
        text: 'Bouvet Island',
    },
    {
        value: 'BR',
        text: 'Brazil',
    },
    {
        value: 'IO',
        text: 'British Indian Ocean Territory',
    },
    {
        value: 'BN',
        text: 'Brunei Darussalam',
    },
    {
        value: 'BG',
        text: 'Bulgaria',
    },
    {
        value: 'BF',
        text: 'Burkina Faso',
    },
    {
        value: 'BI',
        text: 'Burundi',
    },
    {
        value: 'KH',
        text: 'Cambodia',
    },
    {
        value: 'CM',
        text: 'Cameroon',
    },
    {
        value: 'CA',
        text: 'Canada',
    },
    {
        value: 'CV',
        text: 'Cape Verde',
    },
    {
        value: 'KY',
        text: 'Cayman Islands',
    },
    {
        value: 'CF',
        text: 'Central African Republic',
    },
    {
        value: 'TD',
        text: 'Chad',
    },
    {
        value: 'CL',
        text: 'Chile',
    },
    {
        value: 'CN',
        text: 'China',
    },
    {
        value: 'CX',
        text: 'Christmas Island',
    },
    {
        value: 'CC',
        text: 'Cocos (Keeling) Islands',
    },
    {
        value: 'CO',
        text: 'Colombia',
    },
    {
        value: 'KM',
        text: 'Comoros',
    },
    {
        value: 'CG',
        text: 'Congo',
    },
    {
        value: 'CD',
        text: 'Congo, the Democratic Republic of the',
    },
    {
        value: 'CK',
        text: 'Cook Islands',
    },
    {
        value: 'CR',
        text: 'Costa Rica',
    },
    {
        value: 'CI',
        text: "Côte d'Ivoire",
    },
    {
        value: 'HR',
        text: 'Croatia',
    },
    {
        value: 'CU',
        text: 'Cuba',
    },
    {
        value: 'CW',
        text: 'Curaçao',
    },
    {
        value: 'CY',
        text: 'Cyprus',
    },
    {
        value: 'CZ',
        text: 'Czech Republic',
    },
    {
        value: 'DK',
        text: 'Denmark',
    },
    {
        value: 'DJ',
        text: 'Djibouti',
    },
    {
        value: 'DM',
        text: 'Dominica',
    },
    {
        value: 'DO',
        text: 'Dominican Republic',
    },
    {
        value: 'EC',
        text: 'Ecuador',
    },
    {
        value: 'EG',
        text: 'Egypt',
    },
    {
        value: 'SV',
        text: 'El Salvador',
    },
    {
        value: 'GQ',
        text: 'Equatorial Guinea',
    },
    {
        value: 'ER',
        text: 'Eritrea',
    },
    {
        value: 'EE',
        text: 'Estonia',
    },
    {
        value: 'ET',
        text: 'Ethiopia',
    },
    {
        value: 'FK',
        text: 'Falkland Islands (Malvinas)',
    },
    {
        value: 'FO',
        text: 'Faroe Islands',
    },
    {
        value: 'FJ',
        text: 'Fiji',
    },
    {
        value: 'FI',
        text: 'Finland',
    },
    {
        value: 'FR',
        text: 'France',
    },
    {
        value: 'GF',
        text: 'French Guiana',
    },
    {
        value: 'PF',
        text: 'French Polynesia',
    },
    {
        value: 'TF',
        text: 'French Southern Territories',
    },
    {
        value: 'GA',
        text: 'Gabon',
    },
    {
        value: 'GM',
        text: 'Gambia',
    },
    {
        value: 'GE',
        text: 'Georgia',
    },
    {
        value: 'DE',
        text: 'Germany',
    },
    {
        value: 'GH',
        text: 'Ghana',
    },
    {
        value: 'GI',
        text: 'Gibraltar',
    },
    {
        value: 'GR',
        text: 'Greece',
    },
    {
        value: 'GL',
        text: 'Greenland',
    },
    {
        value: 'GD',
        text: 'Grenada',
    },
    {
        value: 'GP',
        text: 'Guadeloupe',
    },
    {
        value: 'GU',
        text: 'Guam',
    },
    {
        value: 'GT',
        text: 'Guatemala',
    },
    {
        value: 'GG',
        text: 'Guernsey',
    },
    {
        value: 'GN',
        text: 'Guinea',
    },
    {
        value: 'GW',
        text: 'Guinea-Bissau',
    },
    {
        value: 'GY',
        text: 'Guyana',
    },
    {
        value: 'HT',
        text: 'Haiti',
    },
    {
        value: 'HM',
        text: 'Heard Island and McDonald Islands',
    },
    {
        value: 'VA',
        text: 'Holy See (Vatican City State)',
    },
    {
        value: 'HN',
        text: 'Honduras',
    },
    {
        value: 'HK',
        text: 'Hong Kong',
    },
    {
        value: 'HU',
        text: 'Hungary',
    },
    {
        value: 'IS',
        text: 'Iceland',
    },
    {
        value: 'IN',
        text: 'India',
    },
    {
        value: 'ID',
        text: 'Indonesia',
    },
    {
        value: 'IR',
        text: 'Iran, Islamic Republic of',
    },
    {
        value: 'IQ',
        text: 'Iraq',
    },
    {
        value: 'IE',
        text: 'Ireland',
    },
    {
        value: 'IM',
        text: 'Isle of Man',
    },
    {
        value: 'IL',
        text: 'Israel',
    },
    {
        value: 'IT',
        text: 'Italy',
    },
    {
        value: 'JM',
        text: 'Jamaica',
    },
    {
        value: 'JP',
        text: 'Japan',
    },
    {
        value: 'JE',
        text: 'Jersey',
    },
    {
        value: 'JO',
        text: 'Jordan',
    },
    {
        value: 'KZ',
        text: 'Kazakhstan',
    },
    {
        value: 'KE',
        text: 'Kenya',
    },
    {
        value: 'KI',
        text: 'Kiribati',
    },
    {
        value: 'KP',
        text: "Korea, Democratic People's Republic of",
    },
    {
        value: 'KR',
        text: 'Korea, Republic of',
    },
    {
        value: 'KW',
        text: 'Kuwait',
    },
    {
        value: 'KG',
        text: 'Kyrgyzstan',
    },
    {
        value: 'LA',
        text: "Lao People's Democratic Republic",
    },
    {
        value: 'LV',
        text: 'Latvia',
    },
    {
        value: 'LB',
        text: 'Lebanon',
    },
    {
        value: 'LS',
        text: 'Lesotho',
    },
    {
        value: 'LR',
        text: 'Liberia',
    },
    {
        value: 'LY',
        text: 'Libya',
    },
    {
        value: 'LI',
        text: 'Liechtenstein',
    },
    {
        value: 'LT',
        text: 'Lithuania',
    },
    {
        value: 'LU',
        text: 'Luxembourg',
    },
    {
        value: 'MO',
        text: 'Macao',
    },
    {
        value: 'MK',
        text: 'Macedonia, the former Yugoslav Republic of',
    },
    {
        value: 'MG',
        text: 'Madagascar',
    },
    {
        value: 'MW',
        text: 'Malawi',
    },
    {
        value: 'MY',
        text: 'Malaysia',
    },
    {
        value: 'MV',
        text: 'Maldives',
    },
    {
        value: 'ML',
        text: 'Mali',
    },
    {
        value: 'MT',
        text: 'Malta',
    },
    {
        value: 'MH',
        text: 'Marshall Islands',
    },
    {
        value: 'MQ',
        text: 'Martinique',
    },
    {
        value: 'MR',
        text: 'Mauritania',
    },
    {
        value: 'MU',
        text: 'Mauritius',
    },
    {
        value: 'YT',
        text: 'Mayotte',
    },
    {
        value: 'MX',
        text: 'Mexico',
    },
    {
        value: 'FM',
        text: 'Micronesia, Federated States of',
    },
    {
        value: 'MD',
        text: 'Moldova, Republic of',
    },
    {
        value: 'MC',
        text: 'Monaco',
    },
    {
        value: 'MN',
        text: 'Mongolia',
    },
    {
        value: 'ME',
        text: 'Montenegro',
    },
    {
        value: 'MS',
        text: 'Montserrat',
    },
    {
        value: 'MA',
        text: 'Morocco',
    },
    {
        value: 'MZ',
        text: 'Mozambique',
    },
    {
        value: 'MM',
        text: 'Myanmar',
    },
    {
        value: 'NA',
        text: 'Namibia',
    },
    {
        value: 'NR',
        text: 'Nauru',
    },
    {
        value: 'NP',
        text: 'Nepal',
    },
    {
        value: 'NL',
        text: 'Netherlands',
    },
    {
        value: 'NC',
        text: 'New Caledonia',
    },
    {
        value: 'NZ',
        text: 'New Zealand',
    },
    {
        value: 'NI',
        text: 'Nicaragua',
    },
    {
        value: 'NE',
        text: 'Niger',
    },
    {
        value: 'NG',
        text: 'Nigeria',
    },
    {
        value: 'NU',
        text: 'Niue',
    },
    {
        value: 'NF',
        text: 'Norfolk Island',
    },
    {
        value: 'MP',
        text: 'Northern Mariana Islands',
    },
    {
        value: 'NO',
        text: 'Norway',
    },
    {
        value: 'OM',
        text: 'Oman',
    },
    {
        value: 'PK',
        text: 'Pakistan',
    },
    {
        value: 'PW',
        text: 'Palau',
    },
    {
        value: 'PS',
        text: 'Palestinian Territory, Occupied',
    },
    {
        value: 'PA',
        text: 'Panama',
    },
    {
        value: 'PG',
        text: 'Papua New Guinea',
    },
    {
        value: 'PY',
        text: 'Paraguay',
    },
    {
        value: 'PE',
        text: 'Peru',
    },
    {
        value: 'PH',
        text: 'Philippines',
    },
    {
        value: 'PN',
        text: 'Pitcairn',
    },
    {
        value: 'PL',
        text: 'Poland',
    },
    {
        value: 'PT',
        text: 'Portugal',
    },
    {
        value: 'PR',
        text: 'Puerto Rico',
    },
    {
        value: 'QA',
        text: 'Qatar',
    },
    {
        value: 'RE',
        text: 'Réunion',
    },
    {
        value: 'RO',
        text: 'Romania',
    },
    {
        value: 'RU',
        text: 'Russian Federation',
    },
    {
        value: 'RW',
        text: 'Rwanda',
    },
    {
        value: 'BL',
        text: 'Saint Barthélemy',
    },
    {
        value: 'SH',
        text: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    {
        value: 'KN',
        text: 'Saint Kitts and Nevis',
    },
    {
        value: 'LC',
        text: 'Saint Lucia',
    },
    {
        value: 'MF',
        text: 'Saint Martin (French part)',
    },
    {
        value: 'PM',
        text: 'Saint Pierre and Miquelon',
    },
    {
        value: 'VC',
        text: 'Saint Vincent and the Grenadines',
    },
    {
        value: 'WS',
        text: 'Samoa',
    },
    {
        value: 'SM',
        text: 'San Marino',
    },
    {
        value: 'ST',
        text: 'Sao Tome and Principe',
    },
    {
        value: 'SA',
        text: 'Saudi Arabia',
    },
    {
        value: 'SN',
        text: 'Senegal',
    },
    {
        value: 'RS',
        text: 'Serbia',
    },
    {
        value: 'SC',
        text: 'Seychelles',
    },
    {
        value: 'SL',
        text: 'Sierra Leone',
    },
    {
        value: 'SG',
        text: 'Singapore',
    },
    {
        value: 'SX',
        text: 'Sint Maarten (Dutch part)',
    },
    {
        value: 'SK',
        text: 'Slovakia',
    },
    {
        value: 'SI',
        text: 'Slovenia',
    },
    {
        value: 'SB',
        text: 'Solomon Islands',
    },
    {
        value: 'SO',
        text: 'Somalia',
    },
    {
        value: 'ZA',
        text: 'South Africa',
    },
    {
        value: 'GS',
        text: 'South Georgia and the South Sandwich Islands',
    },
    {
        value: 'SS',
        text: 'South Sudan',
    },
    {
        value: 'ES',
        text: 'Spain',
    },
    {
        value: 'LK',
        text: 'Sri Lanka',
    },
    {
        value: 'SD',
        text: 'Sudan',
    },
    {
        value: 'SR',
        text: 'Suriname',
    },
    {
        value: 'SJ',
        text: 'Svalbard and Jan Mayen',
    },
    {
        value: 'SZ',
        text: 'Swaziland',
    },
    {
        value: 'SE',
        text: 'Sweden',
    },
    {
        value: 'CH',
        text: 'Switzerland',
    },
    {
        value: 'SY',
        text: 'Syrian Arab Republic',
    },
    {
        value: 'TW',
        text: 'Taiwan, Province of China',
    },
    {
        value: 'TJ',
        text: 'Tajikistan',
    },
    {
        value: 'TZ',
        text: 'Tanzania, United Republic of',
    },
    {
        value: 'TH',
        text: 'Thailand',
    },
    {
        value: 'TL',
        text: 'Timor-Leste',
    },
    {
        value: 'TG',
        text: 'Togo',
    },
    {
        value: 'TK',
        text: 'Tokelau',
    },
    {
        value: 'TO',
        text: 'Tonga',
    },
    {
        value: 'TT',
        text: 'Trinidad and Tobago',
    },
    {
        value: 'TN',
        text: 'Tunisia',
    },
    {
        value: 'TR',
        text: 'Turkey',
    },
    {
        value: 'TM',
        text: 'Turkmenistan',
    },
    {
        value: 'TC',
        text: 'Turks and Caicos Islands',
    },
    {
        value: 'TV',
        text: 'Tuvalu',
    },
    {
        value: 'UG',
        text: 'Uganda',
    },
    {
        value: 'UA',
        text: 'Ukraine',
    },
    {
        value: 'AE',
        text: 'United Arab Emirates',
    },
    {
        value: 'GB',
        text: 'United Kingdom',
    },
    {
        value: 'US',
        text: 'United States',
    },
    {
        value: 'UM',
        text: 'United States Minor Outlying Islands',
    },
    {
        value: 'UY',
        text: 'Uruguay',
    },
    {
        value: 'UZ',
        text: 'Uzbekistan',
    },
    {
        value: 'VU',
        text: 'Vanuatu',
    },
    {
        value: 'VE',
        text: 'Venezuela, Bolivarian Republic of',
    },
    {
        value: 'VN',
        text: 'Viet Nam',
    },
    {
        value: 'VG',
        text: 'Virgin Islands, British',
    },
    {
        value: 'VI',
        text: 'Virgin Islands, U.S.',
    },
    {
        value: 'WF',
        text: 'Wallis and Futuna',
    },
    {
        value: 'EH',
        text: 'Western Sahara',
    },
    {
        value: 'YE',
        text: 'Yemen',
    },
    {
        value: 'ZM',
        text: 'Zambia',
    },
    {
        value: 'ZW',
        text: 'Zimbabwe',
    },
]

export const US_STATES = [
    {
        text: 'Alabama',
        value: 'AL',
    },
    {
        text: 'Alaska',
        value: 'AK',
    },
    {
        text: 'American Samoa',
        value: 'AS',
    },
    {
        text: 'Arizona',
        value: 'AZ',
    },
    {
        text: 'Arkansas',
        value: 'AR',
    },
    {
        text: 'California',
        value: 'CA',
    },
    {
        text: 'Colorado',
        value: 'CO',
    },
    {
        text: 'Connecticut',
        value: 'CT',
    },
    {
        text: 'Delaware',
        value: 'DE',
    },
    {
        text: 'District Of Columbia',
        value: 'DC',
    },
    {
        text: 'Federated States Of Micronesia',
        value: 'FM',
    },
    {
        text: 'Florida',
        value: 'FL',
    },
    {
        text: 'Georgia',
        value: 'GA',
    },
    {
        text: 'Guam',
        value: 'GU',
    },
    {
        text: 'Hawaii',
        value: 'HI',
    },
    {
        text: 'Idaho',
        value: 'ID',
    },
    {
        text: 'Illinois',
        value: 'IL',
    },
    {
        text: 'Indiana',
        value: 'IN',
    },
    {
        text: 'Iowa',
        value: 'IA',
    },
    {
        text: 'Kansas',
        value: 'KS',
    },
    {
        text: 'Kentucky',
        value: 'KY',
    },
    {
        text: 'Louisiana',
        value: 'LA',
    },
    {
        text: 'Maine',
        value: 'ME',
    },
    {
        text: 'Marshall Islands',
        value: 'MH',
    },
    {
        text: 'Maryland',
        value: 'MD',
    },
    {
        text: 'Massachusetts',
        value: 'MA',
    },
    {
        text: 'Michigan',
        value: 'MI',
    },
    {
        text: 'Minnesota',
        value: 'MN',
    },
    {
        text: 'Mississippi',
        value: 'MS',
    },
    {
        text: 'Missouri',
        value: 'MO',
    },
    {
        text: 'Montana',
        value: 'MT',
    },
    {
        text: 'Nebraska',
        value: 'NE',
    },
    {
        text: 'Nevada',
        value: 'NV',
    },
    {
        text: 'New Hampshire',
        value: 'NH',
    },
    {
        text: 'New Jersey',
        value: 'NJ',
    },
    {
        text: 'New Mexico',
        value: 'NM',
    },
    {
        text: 'New York',
        value: 'NY',
    },
    {
        text: 'North Carolina',
        value: 'NC',
    },
    {
        text: 'North Dakota',
        value: 'ND',
    },
    {
        text: 'Northern Mariana Islands',
        value: 'MP',
    },
    {
        text: 'Ohio',
        value: 'OH',
    },
    {
        text: 'Oklahoma',
        value: 'OK',
    },
    {
        text: 'Oregon',
        value: 'OR',
    },
    {
        text: 'Palau',
        value: 'PW',
    },
    {
        text: 'Pennsylvania',
        value: 'PA',
    },
    {
        text: 'Puerto Rico',
        value: 'PR',
    },
    {
        text: 'Rhode Island',
        value: 'RI',
    },
    {
        text: 'South Carolina',
        value: 'SC',
    },
    {
        text: 'South Dakota',
        value: 'SD',
    },
    {
        text: 'Tennessee',
        value: 'TN',
    },
    {
        text: 'Texas',
        value: 'TX',
    },
    {
        text: 'Utah',
        value: 'UT',
    },
    {
        text: 'Vermont',
        value: 'VT',
    },
    {
        text: 'Virgin Islands',
        value: 'VI',
    },
    {
        text: 'Virginia',
        value: 'VA',
    },
    {
        text: 'Washington',
        value: 'WA',
    },
    {
        text: 'West Virginia',
        value: 'WV',
    },
    {
        text: 'Wisconsin',
        value: 'WI',
    },
    {
        text: 'Wyoming',
        value: 'WY',
    },
]

export const TIMEZONES = {
    'Dateline Standard Time': {
        text: '(UTC-12:00) International Date Line West',
        offset: -12,
    },
    'UTC-11': {
        text: '(UTC-11:00) Coordinated Universal Time-11',
        offset: -11,
    },
    'Hawaiian Standard Time': {
        text: '(UTC-10:00) Hawaii',
        offset: -10,
    },
    'Alaskan Standard Time': {
        text: '(UTC-09:00) Alaska',
        offset: -8,
    },
    'Pacific Standard Time (Mexico)': {
        text: '(UTC-08:00) Baja California',
        offset: -7,
    },
    'Pacific Daylight Time': {
        text: '(UTC-07:00) Pacific Daylight Time (US & Canada)',
        offset: -7,
    },
    'Pacific Standard Time': {
        text: '(UTC-08:00) Pacific Standard Time (US & Canada)',
        offset: -8,
    },
    'US Mountain Standard Time': {
        text: '(UTC-07:00) Arizona',
        offset: -7,
    },
    'Mountain Standard Time (Mexico)': {
        text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
        offset: -6,
    },
    'Mountain Standard Time': {
        text: '(UTC-07:00) Mountain Time (US & Canada)',
        offset: -6,
    },
    'Central America Standard Time': {
        text: '(UTC-06:00) Central America',
        offset: -6,
    },
    'Central Standard Time': {
        text: '(UTC-06:00) Central Time (US & Canada)',
        offset: -5,
    },
    'Central Standard Time (Mexico)': {
        text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
        offset: -5,
    },
    'Canada Central Standard Time': {
        text: '(UTC-06:00) Saskatchewan',
        offset: -6,
    },
    'SA Pacific Standard Time': {
        text: '(UTC-05:00) Bogota, Lima, Quito',
        offset: -5,
    },
    'Eastern Standard Time': {
        text: '(UTC-05:00) Eastern Time (US & Canada)',
        offset: -5,
    },
    'Eastern Daylight Time': {
        text: '(UTC-04:00) Eastern Daylight Time (US & Canada)',
        offset: -4,
    },
    'US Eastern Standard Time': {
        text: '(UTC-05:00) Indiana (East)',
        offset: -5,
    },
    'Venezuela Standard Time': {
        text: '(UTC-04:30) Caracas',
        offset: -4.5,
    },
    'Paraguay Standard Time': {
        text: '(UTC-04:00) Asuncion',
        offset: -4,
    },
    'Atlantic Standard Time': {
        text: '(UTC-04:00) Atlantic Time (Canada)',
        offset: -3,
    },
    'Central Brazilian Standard Time': {
        text: '(UTC-04:00) Cuiaba',
        offset: -4,
    },
    'SA Western Standard Time': {
        text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        offset: -4,
    },
    'Pacific SA Standard Time': {
        text: '(UTC-04:00) Santiago',
        offset: -4,
    },
    'Newfoundland Standard Time': {
        text: '(UTC-03:30) Newfoundland',
        offset: -2.5,
    },
    'E. South America Standard Time': {
        text: '(UTC-03:00) Brasilia',
        offset: -3,
    },
    'Argentina Standard Time': {
        text: '(UTC-03:00) Buenos Aires',
        offset: -3,
    },
    'SA Eastern Standard Time': {
        text: '(UTC-03:00) Cayenne, Fortaleza',
        offset: -3,
    },
    'Greenland Standard Time': {
        text: '(UTC-03:00) Greenland',
        offset: -3,
    },
    'Montevideo Standard Time': {
        text: '(UTC-03:00) Montevideo',
        offset: -3,
    },
    'Bahia Standard Time': {
        text: '(UTC-03:00) Salvador',
        offset: -3,
    },
    'UTC-02': {
        text: '(UTC-02:00) Coordinated Universal Time-02',
        offset: -2,
    },
    'Mid-Atlantic Standard Time': {
        text: '(UTC-02:00) Mid-Atlantic - Old',
        offset: -1,
    },
    'Azores Standard Time': {
        text: '(UTC-01:00) Azores',
        offset: 0,
    },
    'Cape Verde Standard Time': {
        text: '(UTC-01:00) Cape Verde Is.',
        offset: -1,
    },
    'Morocco Standard Time': {
        text: '(UTC) Casablanca',
        offset: 1,
    },
    UTC: {
        text: '(UTC) Coordinated Universal Time',
        offset: 0,
    },
    'GMT Standard Time': {
        text: '(UTC) Dublin, Lisbon',
        offset: 1,
    },
    'British Summer Time': {
        text: '(UTC+01:00) Edinburgh, London',
        offset: 1,
    },
    'Greenwich Standard Time': {
        text: '(UTC) Monrovia, Reykjavik',
        offset: 0,
    },
    'W. Europe Standard Time': {
        text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        offset: 2,
    },
    'Central Europe Standard Time': {
        text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        offset: 2,
    },
    'Romance Standard Time': {
        text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
        offset: 2,
    },
    'Central European Standard Time': {
        text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        offset: 2,
    },
    'W. Central Africa Standard Time': {
        text: '(UTC+01:00) West Central Africa',
        offset: 1,
    },
    'Namibia Standard Time': {
        text: '(UTC+01:00) Windhoek',
        offset: 1,
    },
    'GTB Standard Time': {
        text: '(UTC+02:00) Athens, Bucharest',
        offset: 3,
    },
    'Middle East Standard Time': {
        text: '(UTC+02:00) Beirut',
        offset: 3,
    },
    'Egypt Standard Time': {
        text: '(UTC+02:00) Cairo',
        offset: 2,
    },
    'Syria Standard Time': {
        text: '(UTC+02:00) Damascus',
        offset: 3,
    },
    'E. Europe Standard Time': {
        text: '(UTC+02:00) E. Europe',
        offset: 3,
    },
    'South Africa Standard Time': {
        text: '(UTC+02:00) Harare, Pretoria',
        offset: 2,
    },
    'FLE Standard Time': {
        text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        offset: 3,
    },
    'Turkey Standard Time': {
        text: '(UTC+03:00) Istanbul',
        offset: 3,
    },
    'Israel Standard Time': {
        text: '(UTC+02:00) Jerusalem',
        offset: 3,
    },
    'Libya Standard Time': {
        text: '(UTC+02:00) Tripoli',
        offset: 2,
    },
    'Jordan Standard Time': {
        text: '(UTC+03:00) Amman',
        offset: 3,
    },
    'Arabic Standard Time': {
        text: '(UTC+03:00) Baghdad',
        offset: 3,
    },
    'Kaliningrad Standard Time': {
        text: '(UTC+02:00) Kaliningrad',
        offset: 3,
    },
    'Arab Standard Time': {
        text: '(UTC+03:00) Kuwait, Riyadh',
        offset: 3,
    },
    'E. Africa Standard Time': {
        text: '(UTC+03:00) Nairobi',
        offset: 3,
    },
    'Moscow Standard Time': {
        text: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
        offset: 3,
    },
    'Samara Time': {
        text: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
        offset: 4,
    },
    'Iran Standard Time': {
        text: '(UTC+03:30) Tehran',
        offset: 4.5,
    },
    'Arabian Standard Time': {
        text: '(UTC+04:00) Abu Dhabi, Muscat',
        offset: 4,
    },
    'Azerbaijan Standard Time': {
        text: '(UTC+04:00) Baku',
        offset: 5,
    },
    'Mauritius Standard Time': {
        text: '(UTC+04:00) Port Louis',
        offset: 4,
    },
    'Georgian Standard Time': {
        text: '(UTC+04:00) Tbilisi',
        offset: 4,
    },
    'Caucasus Standard Time': {
        text: '(UTC+04:00) Yerevan',
        offset: 4,
    },
    'Afghanistan Standard Time': {
        text: '(UTC+04:30) Kabul',
        offset: 4.5,
    },
    'West Asia Standard Time': {
        text: '(UTC+05:00) Ashgabat, Tashkent',
        offset: 5,
    },
    'Yekaterinburg Time': {
        text: '(UTC+05:00) Yekaterinburg',
        offset: 5,
    },
    'Pakistan Standard Time': {
        text: '(UTC+05:00) Islamabad, Karachi',
        offset: 5,
    },
    'India Standard Time': {
        text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        offset: 5.5,
    },
    'Sri Lanka Standard Time': {
        text: '(UTC+05:30) Sri Jayawardenepura',
        offset: 5.5,
    },
    'Nepal Standard Time': {
        text: '(UTC+05:45) Kathmandu',
        offset: 5.75,
    },
    'Central Asia Standard Time': {
        text: '(UTC+06:00) Nur-Sultan (Astana)',
        offset: 6,
    },
    'Bangladesh Standard Time': {
        text: '(UTC+06:00) Dhaka',
        offset: 6,
    },
    'Myanmar Standard Time': {
        text: '(UTC+06:30) Yangon (Rangoon)',
        offset: 6.5,
    },
    'SE Asia Standard Time': {
        text: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        offset: 7,
    },
    'N. Central Asia Standard Time': {
        text: '(UTC+07:00) Novosibirsk',
        offset: 7,
    },
    'China Standard Time': {
        text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        offset: 8,
    },
    'North Asia Standard Time': {
        text: '(UTC+08:00) Krasnoyarsk',
        offset: 8,
    },
    'Singapore Standard Time': {
        text: '(UTC+08:00) Kuala Lumpur, Singapore',
        offset: 8,
    },
    'W. Australia Standard Time': {
        text: '(UTC+08:00) Perth',
        offset: 8,
    },
    'Taipei Standard Time': {
        text: '(UTC+08:00) Taipei',
        offset: 8,
    },
    'Ulaanbaatar Standard Time': {
        text: '(UTC+08:00) Ulaanbaatar',
        offset: 8,
    },
    'North Asia East Standard Time': {
        text: '(UTC+08:00) Irkutsk',
        offset: 8,
    },
    'Japan Standard Time': {
        text: '(UTC+09:00) Osaka, Sapporo, Tokyo',
        offset: 9,
    },
    'Korea Standard Time': {
        text: '(UTC+09:00) Seoul',
        offset: 9,
    },
    'Cen. Australia Standard Time': {
        text: '(UTC+09:30) Adelaide',
        offset: 9.5,
    },
    'AUS Central Standard Time': {
        text: '(UTC+09:30) Darwin',
        offset: 9.5,
    },
    'E. Australia Standard Time': {
        text: '(UTC+10:00) Brisbane',
        offset: 10,
    },
    'AUS Eastern Standard Time': {
        text: '(UTC+10:00) Canberra, Melbourne, Sydney',
        offset: 10,
    },
    'West Pacific Standard Time': {
        text: '(UTC+10:00) Guam, Port Moresby',
        offset: 10,
    },
    'Tasmania Standard Time': {
        text: '(UTC+10:00) Hobart',
        offset: 10,
    },
    'Yakutsk Standard Time': {
        text: '(UTC+09:00) Yakutsk',
        offset: 9,
    },
    'Central Pacific Standard Time': {
        text: '(UTC+11:00) Solomon Is., New Caledonia',
        offset: 11,
    },
    'Vladivostok Standard Time': {
        text: '(UTC+11:00) Vladivostok',
        offset: 11,
    },
    'New Zealand Standard Time': {
        text: '(UTC+12:00) Auckland, Wellington',
        offset: 12,
    },
    'UTC+12': {
        text: '(UTC+12:00) Coordinated Universal Time+12',
        offset: 12,
    },
    'Fiji Standard Time': {
        text: '(UTC+12:00) Fiji',
        offset: 12,
    },
    'Magadan Standard Time': {
        text: '(UTC+12:00) Magadan',
        offset: 12,
    },
    'Kamchatka Standard Time': {
        text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
        offset: 13,
    },
    'Tonga Standard Time': {
        text: "(UTC+13:00) Nuku'alofa",
        offset: 13,
    },
    'Samoa Standard Time': {
        text: '(UTC+13:00) Samoa',
        offset: 13,
    },
}
