import { ICONS } from '../../common/constants'
import useAuth from '../../common/hooks/useAuth'
import useData from '../../common/hooks/useData'
import useTranslations from '../../common/hooks/useTranslations'
import Button from '../../components/common/Button'
import ButtonGroup from '../../components/common/ButtonGroup'
import SectionContainer from '../../components/common/SectionContainer'
import Spinner from '../../components/common/Spinner'
import Toggle from '../../components/common/Toggle'

export default function Menu() {
    const auth = useAuth()
    const { t, tLoading } = useTranslations()

    const pagesUrl = auth.isClient
        ? '/v1/pages?showOnClientMenu=true&limit=200&page=1'
        : '/v1/pages?showOnTeamMenu=true&limit=200&page=1'

    const { pages } = useData(pagesUrl, 'pages', (data) => data?.results || [])

    if (tLoading) return <Spinner />

    return (
        <>
            <SectionContainer>
                <ButtonGroup grid>
                    {auth.isAdmin && (
                        <Button
                            outline
                            card
                            href="/logs"
                            text={t('more_menu.grid_items.logs', 'Logs')}
                            icon={ICONS.FILE_TEXT_ACTIVE}
                        />
                    )}
                    {auth.isAdmin && (
                        <>
                            <Button
                                href="/pdf-contracts"
                                outline
                                card
                                icon={ICONS.FEATHER_ACTIVE}
                                text={t(
                                    'more_menu.grid_items.pdf_contracts',
                                    'PDF Contracts',
                                )}
                            />

                            <Button
                                href="/html-contracts"
                                outline
                                card
                                icon={ICONS.FEATHER_ACTIVE}
                                text={t(
                                    'more_menu.grid_items.custom_contracts',
                                    'Custom Contracts',
                                )}
                            />

                            <Button
                                href="/repos"
                                outline
                                card
                                // icon={ICONS.FEATHER_ACTIVE}
                                cardTags={[
                                    {
                                        text: 'Beta',
                                        outline: true,
                                    },
                                ]}
                                text={t('more_menu.grid_items.repos', 'Repos')}
                            />

                            <Button
                                href="/storybook"
                                outline
                                card
                                // icon={ICONS.FEATHER_ACTIVE}
                                cardTags={[
                                    {
                                        text: 'Beta',
                                        outline: true,
                                    },
                                ]}
                                text={t(
                                    'more_menu.grid_items.components',
                                    'Components',
                                )}
                            />

                            <Button
                                href={'/edit-pages'}
                                outline
                                card
                                icon={ICONS.MONITOR_ACTIVE}
                                text={t(
                                    'more_menu.grid_items.edit_pages',
                                    'Pages',
                                )}
                            />
                            <Button
                                href="/plain-forms"
                                outline
                                card
                                icon={ICONS.SHARE_2_ACTIVE}
                                text={t(
                                    'more_menu.grid_items.plain_forms',
                                    'Forms',
                                )}
                            />
                        </>
                    )}

                    {(auth.isStaff || auth.isAdmin) && (
                        <Button
                            href={
                                auth.isStaff
                                    ? '/timesheets'
                                    : '/timesheets-users'
                            }
                            outline
                            card
                            cardTags={[
                                {
                                    text: 'Beta',
                                    outline: true,
                                },
                            ]}
                            icon={ICONS.CLOCK_ACTIVE}
                            text={t(
                                'more_menu.grid_items.timesheets',
                                'Timesheets',
                            )}
                        />
                    )}

                    <Button
                        href="/vault"
                        outline
                        card
                        icon={ICONS.LOCK_ACTIVE}
                        text={t('more_menu.grid_items.passwords', 'Passwords')}
                    />

                    <Button
                        href="/pages/events"
                        outline
                        card
                        icon={ICONS.CALENDAR_ACTIVE}
                        text={t('more_menu.grid_items.events', 'Events')}
                    />
                    {(auth.isClient || auth.isAdmin) && (
                        <Button
                            href="/contact-forms"
                            outline
                            card
                            text={t(
                                'more_menu.grid_items.contact_forms',
                                'Contact Forms',
                            )}
                            icon={ICONS.MAIL_ACTIVE}
                            cardTags={[
                                {
                                    text: 'Beta',
                                    outline: true,
                                },
                            ]}
                        />
                    )}

                    {auth.isAssistant && (
                        <Button
                            href="/edit-pages"
                            outline
                            card
                            icon={ICONS.MONITOR_ACTIVE}
                            text={t('more_menu.grid_items.edit_pages', 'Pages')}
                        />
                    )}

                    <Button
                        href="/donate"
                        outline
                        card
                        icon={ICONS.HEART_ACTIVE}
                        text={t('more_menu.grid_items.donations', 'Donations')}
                    />
                    <Button
                        href="/referrals"
                        outline
                        card
                        text={t(
                            'more_menu.grid_items.referrals_program',
                            'Referrals Program',
                        )}
                    />

                    <Button
                        href="/contactus"
                        outline
                        card
                        icon={ICONS.MAIL_ACTIVE}
                        text={t('more_menu.grid_items.contact', 'Contact')}
                    />
                </ButtonGroup>
            </SectionContainer>
            {pages?.length ? (
                <SectionContainer>
                    <Toggle title="Pages" open>
                        <ButtonGroup grid>
                            {pages.map((page) => (
                                <Button
                                    key={page.id}
                                    href={`/pages/${page.slug}`}
                                    outline
                                    card
                                    text={page.title}
                                />
                            ))}
                        </ButtonGroup>
                    </Toggle>
                </SectionContainer>
            ) : null}
        </>
    )
}
