import React, { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/common/Button'
import { useStore } from '../../../common/Context'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import FormSteps from '../../../common/FormSteps'
import authStorage from '../../../common/authStorage'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import PasswordInput from '../../../components/common/data-form/PasswordInput'
import SectionContainer from '../../../components/common/SectionContainer'

function Register() {
    const navigate = useNavigate()

    const [_state, dispatch] = useStore()
    const [searchParams] = useSearchParams()
    const referralCode = searchParams.get('code')
    const initEmail = searchParams.get('email')
    const pendingOrgMemberInvite = searchParams.get('pendingOrgMemberInvite')

    const initialData =
        process.env.NODE_ENV === 'development'
            ? {
                  email: initEmail || `t${Date.now()}@example.com`,
                  password: 'pass1234',
              }
            : { email: initEmail || '', password: '' }

    const [data, setData] = useState(initialData)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()

    const onSubmit = async (e) => {
        setError('')
        if (!e.target.reportValidity()) return
        e.preventDefault()
        const body = {
            email: data.email,
            password: data.password,
        }
        if (referralCode) {
            body.referralCode = referralCode
        }
        if (pendingOrgMemberInvite) {
            body.pendingOrgMemberInvite = pendingOrgMemberInvite
        }
        setLoading(true)
        const { responseData: registerResponseData, error: registerError } =
            await fetchAPI(`/v1/auth/register`, body, 'POST')
        setLoading(false)

        if (registerError) {
            setError(registerError)
            return
        }

        const { user, tokens } = registerResponseData

        authStorage.setItem('access_token', tokens.access.token)
        authStorage.setItem('refresh_token', tokens.refresh.token)

        dispatch({ type: 'LOGIN', payload: user })

        navigate('/register/details')
    }

    return (
        <SectionContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <form onSubmit={onSubmit}>
                <header>
                    <img
                        alt="logo"
                        srcSet="/img/agency-couturemulticolor-1.png 1x, /img/agency-couturemulticolor-1-sm.png 2x"
                        width="80"
                        height="98"
                    />
                    <h1>Register</h1>
                    <FormSteps step={1} totalSteps={3} />
                </header>

                <label htmlFor="form-email">Email</label>
                <TextInput
                    id="form-email"
                    name="email"
                    type="email"
                    value={data.email}
                    onChange={(v) => setData({ ...data, email: v })}
                    required
                    placeholder="name@email.com"
                />

                <label htmlFor="form-password">Password</label>
                <PasswordInput
                    required
                    placeholder="******"
                    id="form-password"
                    name="password"
                    value={data.password}
                    onChange={(v) =>
                        setData({
                            ...data,
                            password: v,
                        })
                    }
                />

                <Button text="Continue" isLoading={loading} type="submit" />
            </form>
            <footer>
                <div className="links">
                    Already have an account? <Link to="/login">Login</Link>
                </div>
            </footer>
        </SectionContainer>
    )
}

export default Register
