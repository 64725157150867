import { useStore } from '../../common/Context'
import useModal from '../../common/hooks/useModal'
import Button from './Button'

function ConfirmModalContent(props) {
    const { text, onCancel, onConfirm, cancelText, confirmText } = props
    const [state] = useStore()
    const { setModal } = useModal()

    const handleCancel = () => {
        setModal(null)
        onCancel && onCancel()
    }

    return (
        <>
            <p>{text}</p>
            <div className="modal-buttons">
                <Button
                    small
                    destructive
                    link
                    onClick={handleCancel}
                    text={cancelText || 'Cancel'}
                    disabled={state.isModalLoading}
                />
                <Button
                    small
                    onClick={onConfirm}
                    isLoading={state.isModalLoading}
                    text={confirmText || 'Yes'}
                />
            </div>
        </>
    )
}

export default ConfirmModalContent
