// .acronym ok
import Spinner from '../components/common/Spinner'
import ErrorMessage from '../components/common/ErrorMessage'
import { TASK_STATUSES, TIMEZONES } from './constants'

export function sumMoney(a, b, precision = 2) {
    return Number((a + b).toFixed(precision))
}

export function multiplyMoney(a, b, precision = 2) {
    return Number((a * b).toFixed(precision))
}

export const compareStrFields = (fieldName) => (itemA, itemB) => {
    if (!fieldName) return itemA?.localeCompare(itemB)
    return itemA?.[fieldName]?.localeCompare(itemA?.[fieldName])
}

export const comparePrimitiveArrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false
    return arr1.every((item, index) => item === arr2[index])
}

export const comparePrimitiveObjects = (obj1, obj2) => {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    if (keys1.length !== keys2.length) return false
    if (!keys1.every((key) => keys2.includes(key))) return false
    for (let i = 0; i < keys1.length; i++) {
        const key1 = keys1[i]
        if (obj1[key1] !== obj2[key1]) return false
    }

    return true
}

export const showLoadingHtml = (...loadingCases) => {
    if (!loadingCases.some((isLoading) => isLoading)) return false
    return <Spinner />
}

export const showErrorHtml = (...errorCases) => {
    const firstError = errorCases.find((error) => error)
    if (!firstError) return false
    return <ErrorMessage section>{firstError}</ErrorMessage>
}

export function getUserDisplayName(user) {
    if (!user) return ''
    let result = ''
    if (user.firstName && !user.lastName) {
        result = user.firstName
    } else if (!user.firstName && user.lastName) {
        result = user.lastName
    } else if (user.firstName && user.lastName) {
        result = `${user.firstName} ${user.lastName}`
    } else if (user.email) {
        result = user.email
    } else {
        result = '[No username]'
    }
    return result
}

export function getCommissionDisplayAmount(type, amount = 0, pretty) {
    if (type !== 'percentage') {
        return pretty
            ? formatMoney(amount, false, 'commission')
            : amount.toFixed(2)
    } else {
        return pretty ? `${String(amount * 100)}%` : String(amount * 100)
    }
}

export function getCommissionDBAmount(type, text) {
    if (type === 'none') return 0
    if (type === 'money') return Number(text)
    return Number((Number(text) / 100).toFixed(2))
}

export const formatMoney = (amount, noCurrency = false, source = 'stripe') => {
    let money
    if (!amount) {
        money = '0.00'
    } else {
        if (source === 'stripe') {
            money = String(amount).slice(0, -2) + '.' + String(amount).slice(-2)
        } else {
            money = Number(amount).toFixed(2)
        }
    }

    return noCurrency ? money : '$' + money
}

export const formatDate = (date) => {
    if (!date) return null
    const result = new Date(date * 1000).toLocaleDateString()
    return result
}

export function prettifyDate(date) {
    const now = new Date()
    const seconds = Math.floor((now - date) / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (seconds < 60) {
        return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`
    } else if (minutes < 60) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
    } else if (hours < 24) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
    } else if (days < 7) {
        return `${days} ${days === 1 ? 'day' : 'days'} ago`
    } else {
        return date.toLocaleDateString()
    }
}

export const formatDateTime = (date, onlyDate, prettier) => {
    if (!date) return null

    if (onlyDate) {
        return new Date(date).toLocaleDateString()
    }

    if (prettier) {
        return prettifyDate(new Date(date))
    }

    const result =
        new Date(date).toLocaleDateString() +
        ' ' +
        new Date(date).toLocaleTimeString()
    return result
}

export function isoToDatetimeLocal(isoString) {
    const date = new Date(isoString)

    const YYYY = date.getFullYear()
    const MM = String(date.getMonth() + 1).padStart(2, '0')
    const DD = String(date.getDate()).padStart(2, '0')
    const HH = String(date.getHours()).padStart(2, '0')
    const MI = String(date.getMinutes()).padStart(2, '0')

    return `${YYYY}-${MM}-${DD}T${HH}:${MI}`
}

export const formatDateForInput = (timestamp) => {
    if (!timestamp) return ''
    return new Date(timestamp * 1000).toISOString().split('T')[0]
}

export const formatDateTimeForInput = (time) => {
    if (!time) return ''
    return new Date(time).toISOString().substring(0, 16)
}

export function getZonedTimeFromUTCTime(timezoneSetting, time = Date.now()) {
    const tzOffset = timezoneSetting
        ? -TIMEZONES[timezoneSetting].offset * 60 * 60 * 1000
        : new Date().getTimezoneOffset() * 60000

    const offsetTimeNow = time - tzOffset
    return offsetTimeNow
}

export function getDisplayDateTimeFromTime(time) {
    return new Date(time).toGMTString().substring(0, 22)
}

export function getTimeWithoutZone(timezoneSetting, time) {
    if (!time) return ''
    const tzOffset = timezoneSetting
        ? -TIMEZONES[timezoneSetting].offset * 60 * 60 * 1000
        : new Date().getTimezoneOffset() * 60000

    const offsetTimeNow = time + tzOffset
    return offsetTimeNow
}

export function getTimeFromInputStr(str) {
    const year = str.substring(0, 4)
    const month = Number(str.substring(5, 7)) - 1
    const date = str.substring(8, 10)
    const hour = str.substring(11, 13)
    const minute = str.substring(14, 16)
    return Date.UTC(year, month, date, hour, minute)
}

export const getWeek = (date) => {
    const firstWeekday = new Date(
        date.getFullYear(),
        date.getMonth(),
        1,
    ).getDay()
    const offsetDate = date.getDate() + firstWeekday - 1
    return String(Math.floor(offsetDate / 7))
}

export const getQuarter = (date) => {
    return String(Math.floor((date.getMonth() + 3) / 3))
}

function treatAsUTC(date) {
    const result = new Date(date)
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
    return result
}

export function daysBetween(startDate, endDate) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000
    return Math.floor(
        (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay,
    )
}

export const getUnitSumFromCalendar = (unit, range, calendar) => {
    switch (range) {
        case 'year': {
            const yearData = calendar[unit.year]
            if (!yearData) return 0
            const amounts = Object.values(yearData)
            const sum = amounts.reduce((a, b) => a + b, 0)
            return sum
        }
        case 'quarter': {
            const yearData = calendar[unit.year]
            if (!yearData) return 0
            let totalFromMonths = 0
            const fromMonth = (unit.quarter - 1) * 3
            const toMonth = fromMonth + 3
            for (let month = fromMonth; month <= toMonth; month++) {
                if (!yearData[month]) continue
                totalFromMonths += yearData[month]
            }
            return totalFromMonths
        }
        case 'month': {
            return calendar?.[unit.year]?.[unit.month] || 0
        }
        case '': {
            let totalSum = 0
            for (const yearData of Object.values(calendar)) {
                if (!yearData) continue
                const amounts = Object.values(yearData)
                const yearSum = amounts.reduce((a, b) => a + b, 0)
                totalSum += yearSum
            }
            return totalSum
        }
        default:
            return 0
    }
}

export const getTaskPriorityClass = (priority) => {
    switch (priority) {
        case 'high':
            return 'alert'
        case 'medium':
            return 'warning'
        case 'low':
            return 'primary'
        default:
            return ''
    }
}

export function setToast(dispatch, text, type = 'success') {
    const toastDate = Date.now()
    dispatch({
        type: 'SET_TOAST',
        payload: { text, date: toastDate, messageType: type },
    })

    setTimeout(() => {
        dispatch({
            type: 'CLEAR_TOAST',
            payload: toastDate,
        })
    }, 5000)
}

export function parseResourcePath(path, allParams, item = {}, filter = {}) {
    const pathCases = typeof path === 'string' ? [{ path }] : path
    const pathItem = pathCases.find((pathCase) => {
        if (pathCase.defined) {
            const propertiesDefined = pathCase.defined.filter(
                (property) => typeof allParams[property] !== 'undefined',
            )
            if (propertiesDefined.length === pathCase.defined.length) {
                return true
            }
        } else {
            return true
        }
        return false
    })
    const pathStr = pathItem.path
    const hasParams = pathStr.lastIndexOf('?') !== -1
    const paramsStr = hasParams
        ? pathStr.substr(pathStr.lastIndexOf('?') + 1)
        : ''
    const baseStr = hasParams
        ? pathStr.substr(1, pathStr.lastIndexOf('?') - 1)
        : pathStr.substr(1)

    const resultBaseParts = []
    const baseParts = baseStr.split('/')
    for (const part of baseParts) {
        if (part.startsWith(':item.')) {
            const field = part.substr(':item.'.length)
            if (field.includes('.')) {
                // TODO also for params
                const pathParts = field.split('.')
                resultBaseParts.push(item[pathParts[0]][pathParts[1]])
            } else {
                resultBaseParts.push(item[field])
            }
        } else if (part.startsWith(':filter.')) {
            const field = part.substr(':filter.'.length)
            resultBaseParts.push(filter[field])
        } else if (part.startsWith(':')) {
            resultBaseParts.push(allParams[part.substr(1)])
        } else {
            resultBaseParts.push(part)
        }
    }

    const resultParamsParts = []
    const paramParts = paramsStr.split('&').filter((part) => part !== '')
    for (const part of paramParts) {
        if (!part.includes('=')) {
            resultParamsParts.push(part)
        } else {
            const field = part.split('=')[0]
            const value = part.split('=')[1]
            if (value.startsWith(':item.')) {
                const property = value.substr(':item.'.length)
                resultParamsParts.push(field + '=' + item[property])
            } else if (value.startsWith(':')) {
                resultParamsParts.push(field + '=' + allParams[value.substr(1)])
            } else {
                resultParamsParts.push(field + '=' + value)
            }
        }
    }

    let result = ''
    result += '/'
    result += resultBaseParts.join('/')

    if (paramParts.length) {
        result += '?'
        result += resultParamsParts.join('&')
    }

    return result
}

export function getFacebookLoginRequestUri(userId, accountId) {
    return `https://www.facebook.com/v15.0/dialog/oauth?client_id=${
        process.env.REACT_APP_FACEBOOK_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
        `${window.location.origin}/callbacks/facebook-login`,
    )}&state=${userId}${
        accountId ? `-${accountId}` : ''
    }&scope=pages_show_list,pages_read_engagement,pages_manage_posts,instagram_basic,instagram_content_publish&auth_type=rerequest`
}

export function getProcessedNotif(notif) {
    let title, subtitle, link
    switch (notif.notifType) {
        case 'vendorAccountSubmission':
            title = `Vendor account submission for ${notif?.user1?.firstName} ${notif?.user1?.lastName}`
            subtitle =
                'The user requested a vendor account, check for submission of tax exemption form(s), proof of work and acronym'
            link = `/users/${notif.user1Id}/settings`
            break
        case 'vendorProductCreation':
            title = `Product created by ${notif?.user1?.firstName} ${notif?.user1?.lastName}`
            subtitle = `The vendor created a new product titled "${notif?.field1}", check your stores to review and publish`
            break
        case 'commentAdded':
            switch (notif?.field1) {
                case 'task':
                    title = `New comment added in task`
                    subtitle = notif?.field3 && `Task: ${notif.field3}`
                    link = `/tasks/${notif?.field2}`
                    break
                default:
                    break
            }
            break
        case 'orgMemberInvite':
            title = `New invitation`
            subtitle = notif?.field1
            link = `/profile/organizations`
            break
        default:
            break
    }
    return { ...notif, title, subtitle, link }
}

export function copyToClipboard(text) {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
}

export function getHoursAndMinutesFromMinutes(minutes) {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    return { hours, minutes: remainingMinutes }
}

export function openUrlInNewTab(url) {
    const newWindow = window.open(
        url.startsWith('http') ||
            url.startsWith('tel:') ||
            url.startsWith('mailto:')
            ? url
            : `https://${url}`,
        '_blank',
        'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
}

export function addressToString(address) {
    return [
        address.address,
        address.city,
        address.state,
        address.zip,
        address.country,
    ]
        .filter((item) => !!item)
        .join(', ')
}

export function getCellStyleForWidth(columnWidth) {
    return {
        flexBasis: `${columnWidth}%`,
    }
}

export function getFirstDateOfBiweek(date) {
    const isoDateStr = date.toISOString().split('T')[0]
    const currIsoDateTimeStr = `${isoDateStr}T00:00:00.000Z`
    const initMondayIsoDateTimeStr = '2023-01-02T00:00:00.000Z'

    const currDate = new Date(currIsoDateTimeStr)
    const initDate = new Date(initMondayIsoDateTimeStr)
    const timeDiff = currDate.getTime() - initDate.getTime()

    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24))
    const dayCountInBiweek = daysDiff % 14

    const firstDayOfBiweek = new Date(currDate)
    firstDayOfBiweek.setDate(firstDayOfBiweek.getDate() - dayCountInBiweek)

    return firstDayOfBiweek
}

export function getDateInDays(date, days) {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + days)
    return newDate
}

export function getOneMsBeforeDate(date) {
    return new Date(date.getTime() - 1)
}

export function getTimesheetEndDateFromStartDate(date) {
    const endDate = new Date(date)

    let dayOfMonthAfter1Ms = new Date(date.getTime() + 1)
    dayOfMonthAfter1Ms = dayOfMonthAfter1Ms.getDate()

    if (dayOfMonthAfter1Ms !== 15) {
        endDate.setDate(15)
    } else {
        endDate.setMonth(date.getMonth() + 1)
        endDate.setDate(1)
    }
    endDate.setHours(0, 0, 0, 0)
    return endDate
}

export function getLocalDateStr(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
}

export function getProductTagAvailabilityGroups(usedTags, productTags) {
    const processedUsedTags = usedTags
        .map((t) => t.trim().toLowerCase())
        .filter((t) => t)

    const processedShopifyTags = productTags.map((t) => t.toLowerCase())

    const normalizedUsedTags = new Set(processedUsedTags)
    const normalizedShopifyTags = new Set(processedShopifyTags)

    const unavailableTags = []
    const availableTags = []
    const nonExistingTags = []

    for (const tag of normalizedShopifyTags) {
        if (normalizedUsedTags.has(tag)) {
            unavailableTags.push(tag)
        } else {
            availableTags.push(tag)
        }
    }

    for (const tag of normalizedUsedTags) {
        if (!normalizedShopifyTags.has(tag)) {
            nonExistingTags.push(tag)
        }
    }

    return { unavailableTags, availableTags, nonExistingTags }
}

export function getTimezoneInfo(date) {
    // eslint-disable-next-line no-undef
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

    const timeZoneOffsetMinutes = date.getTimezoneOffset()
    const sign = timeZoneOffsetMinutes < 0 ? '+' : '-'
    const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffsetMinutes / 60))
    const timeZoneOffsetMinutesRemainder = Math.abs(timeZoneOffsetMinutes) % 60
    const timeZoneOffset = `${sign}${timeZoneOffsetHours
        .toString()
        .padStart(2, '0')}:${timeZoneOffsetMinutesRemainder
        .toString()
        .padStart(2, '0')}`

    return { name: timeZoneName, offset: timeZoneOffset }
}

export function getHoursMinutesBetween(date1, date2) {
    const diff = date2.getTime() - date1.getTime()
    const minutes = Math.floor(diff / 1000 / 60)
    const hours = Math.floor(minutes / 60)
    let finalMinutes = minutes % 60
    if (finalMinutes < 10) {
        finalMinutes = `0${finalMinutes}`
    }
    return { hours, minutes: finalMinutes }
}

export function getContactDetailsUpdateBody(data, contactDetails) {
    const basicFields = [
        'alternativeFirstName',
        'alternativeLastName',
        'nickname',
        'facebook',
        'tiktok',
        'youtube',
        'twitter',
        'pinterest',
        'snapchat',
        'linkedin',
        'nextdoor',
        'github',
        'website',
    ]
    const result = {}
    if (!contactDetails) {
        for (const field of basicFields) {
            if (data[field]) {
                result[field] = data[field]
            }
        }
        if (data.marketingOptIn) {
            result.marketingOptIn = data.marketingOptIn
        }
        if (data.phones) {
            result.phones = data.phones.map((p) => ({
                ...p,
                phoneType: p.phoneType || 'other',
            }))
        }
    } else {
        for (const field of basicFields) {
            if (data[field] || data[field] === '') {
                result[field] = data[field]
            }
        }
        if (data.marketingOptIn === true || data.marketingOptIn === false) {
            result.marketingOptIn = data.marketingOptIn
        }
        if (data.phones) {
            result.phones = contactDetails.phones || []

            for (const { phone, phoneType, dbId } of data.phones) {
                if (!phone && phone !== '') continue

                const existingPhoneIndex = result.phones.findIndex(
                    (p) => p._id && p._id === dbId,
                )

                if (existingPhoneIndex === -1) {
                    result.phones.push({
                        phone,
                        phoneType: phoneType || 'other',
                    })
                } else {
                    result.phones[existingPhoneIndex].phone = phone
                    result.phones[existingPhoneIndex].phoneType = phoneType
                }
            }
        }
    }

    if (result.phones?.length) {
        result.phones = result.phones
            .filter((p) => p.phone)
            .map((p) => ({
                phone: p.phone,
                phoneType: p.phoneType,
                isPreferred: p.isPreferred,
                extension: p.extension,
            }))
    }

    const hasKeys = Object.keys(result).length > 0
    return hasKeys ? result : null
}

export function markdownToJsx(str) {
    if (!str) return null
    const htmlStr = str
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/__(.*?)__/g, '<strong>$1</strong>')
        .replace(/\*(.*?)\*/g, '<em>$1</em>')
        .replace(/_(.*?)_/g, '<em>$1</em>')
        .replace(/~~(.*?)~~/g, '<del>$1</del>')
        .replace(/`(.*?)`/g, '<code>$1</code>')
        .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>')
        .replace(/\n/g, '<br/>')
    const sanitizedHtmlStr = sanitizeHTML(htmlStr)
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlStr }} />
}

export function getInnerTextFromHTML(html) {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = html
    const text = wrapper.innerText
    wrapper.remove()
    return text
}

export function sanitizeHTML(str) {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = str

    const unsafeTags = ['script', 'style', 'iframe']
    unsafeTags.forEach((tag) => {
        const elements = wrapper.getElementsByTagName(tag)
        for (let i = elements.length - 1; i >= 0; i--) {
            elements[i].parentNode.removeChild(elements[i])
        }
    })

    const elements = wrapper.getElementsByTagName('*')
    for (let i = elements.length - 1; i >= 0; i--) {
        const element = elements[i]
        const attributes = element.attributes
        for (let j = attributes.length - 1; j >= 0; j--) {
            const attribute = attributes[j]
            if (attribute.name !== 'href') {
                element.removeAttribute(attribute.name)
            }
        }
    }

    return wrapper.innerHTML
}

export function getNextStatuses(status, role) {
    if (role !== 'admin') {
        switch (status) {
            case TASK_STATUSES.OPEN:
                return [TASK_STATUSES.ACCEPTED, TASK_STATUSES.BLOCKED]
            case TASK_STATUSES.ACCEPTED:
                return [TASK_STATUSES.REVIEW, TASK_STATUSES.BLOCKED]
            case TASK_STATUSES.REVIEW:
                return [TASK_STATUSES.ACCEPTED]
            case TASK_STATUSES.BLOCKED:
                return [TASK_STATUSES.ACCEPTED]
            default:
                return []
        }
    }
    switch (status) {
        case TASK_STATUSES.REVIEW:
            return [TASK_STATUSES.REJECTED, TASK_STATUSES.COMPLETE]
        case TASK_STATUSES.REJECTED:
        case TASK_STATUSES.ACCEPTED:
            return [
                TASK_STATUSES.OPEN,
                TASK_STATUSES.REVIEW,
                TASK_STATUSES.COMPLETE,
                TASK_STATUSES.BLOCKED,
            ]
        case TASK_STATUSES.OPEN:
            return [
                TASK_STATUSES.ACCEPTED,
                TASK_STATUSES.COMPLETE,
                TASK_STATUSES.BLOCKED,
            ]
        case TASK_STATUSES.DENIED:
            return [TASK_STATUSES.OPEN, TASK_STATUSES.COMPLETE]
        case TASK_STATUSES.APPROVAL:
        case TASK_STATUSES.RECREATION:
            return [TASK_STATUSES.OPEN, TASK_STATUSES.COMPLETE]
        case TASK_STATUSES.COMPLETE:
            return [TASK_STATUSES.OPEN]
        case TASK_STATUSES.BLOCKED:
            return [TASK_STATUSES.ACCEPTED]
        default:
            return []
    }
}

export function setSearchParams(paramsObj) {
    const hash = window.location.hash
    let newLocation = `${window.location.pathname}`
    const newParams = new URLSearchParams()

    for (const [key, value] of Object.entries(paramsObj)) {
        if (value === undefined) {
            continue
        }
        if (Array.isArray(value)) {
            value.forEach((v) => newParams.append(key, v))
        } else {
            newParams.set(key, value)
        }
    }
    if (newParams.toString().length) {
        newLocation += `?${newParams}`
    }
    if (hash) {
        newLocation += hash
    }
    window.history.replaceState({}, '', newLocation)
}

export async function setDataUrls(htmlStr = '', blobUrls = null) {
    if (blobUrls === null) {
        blobUrls = htmlStr.match(/blob:[^"]+/g) || []
    }
    const dataUrlPromises = blobUrls.map(async (blobUrl) => {
        const result = await fetch(blobUrl)
        const blob = await result.blob()
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                resolve(reader.result)
            }
            reader.readAsDataURL(blob)
        })
    })

    const dataURLs = await Promise.all(dataUrlPromises)
    return dataURLs.reduce((acc, dataUrl, index) => {
        const blobUrl = blobUrls[index]
        return acc.replace(blobUrl, dataUrl)
    }, htmlStr)
}

export function dataURLToBlobUrl(dataURL) {
    const parts = dataURL.split(';base64,')
    const contentType = parts[0].split(':')[1]
    const raw = atob(parts[1])
    const rawLength = raw.length

    const uInt8Array = new Uint8Array(rawLength)

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }

    const blob = new Blob([uInt8Array], { type: contentType })

    const blobUrl = URL.createObjectURL(blob)

    return blobUrl
}

export function cleanSlugInput(str) {
    return str
        .replace(/[^a-zA-Z0-9-_]/g, '')
        .replace(/\s/g, '')
        .toLowerCase()
}
