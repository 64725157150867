import Button from '../components/common/Button'
import ErrorMessage from '../components/common/ErrorMessage'

export default function AppError() {
    return (
        <ErrorMessage section>
            Something went wrong.{' '}
            <Button
                inline
                white
                small
                outline
                text="Go to homepage"
                onClick={() => (window.location.href = '/')}
            />
        </ErrorMessage>
    )
}
