import { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../Button'
import { dataURLToBlobUrl, sanitizeHTML } from '../../../common/helpers'
import TextInput from './TextInput'
import InlineStack from '../InlineStack'
import Popover from '../Popover'
import { ICONS } from '../../../common/constants'
import CheckboxInput from './CheckboxInput'
import ButtonGroup from '../ButtonGroup'
import FileInput from './FileInput'

function rgbToHex(rgb) {
    const result = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/.exec(rgb)
    return result
        ? `#${parseInt(result[1], 10).toString(16).padStart(2, '0')}${parseInt(result[2], 10).toString(16).padStart(2, '0')}${parseInt(result[3], 10).toString(16).padStart(2, '0')}`
        : 'transparent'
}
function resizeImageFile(file) {
    return new Promise((resolve, reject) => {
        if (
            !file.type.includes('image/jpeg') &&
            !file.type.includes('image/png')
        ) {
            return reject(
                new Error(
                    'Invalid file type. Please select a JPEG or PNG image.',
                ),
            )
        }

        const reader = new FileReader()

        reader.onload = function (event) {
            const img = new Image()
            img.src = event.target.result

            img.onload = function () {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')

                const MAX_WIDTH = 1000
                const MAX_HEIGHT = 1000
                let width = img.width
                let height = img.height

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height = Math.round((height * MAX_WIDTH) / width)
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width = Math.round((width * MAX_HEIGHT) / height)
                        height = MAX_HEIGHT
                    }
                }

                canvas.width = width
                canvas.height = height

                ctx.drawImage(img, 0, 0, width, height)

                const quality = 0.8
                const compressedDataUrl = canvas.toDataURL(file.type, quality)

                canvas.remove()

                resolve(compressedDataUrl)
            }

            img.onerror = function () {
                reject(new Error('Failed to load image.'))
            }
        }

        reader.onerror = function () {
            reject(new Error('Failed to read file.'))
        }

        reader.readAsDataURL(file)
    })
}

export default function HtmlInput({
    value,
    onChange,
    defaultValue,
    id,
    blobs,
    setBlobs,
    ...rest
}) {
    const [initValue, setInitValue] = useState(value)
    const editableRef = useRef(null)
    const formatRef = useRef(null)
    const alignRef = useRef(null)
    const linkRef = useRef(null)
    const [showCode, setShowCode] = useState(false)
    const [linkUrl, setLinkUrl] = useState('')
    const [openInNewTab, setOpenInNewTab] = useState(false)
    const [savedSelection, setSavedSelection] = useState(null)
    const [color, setColor] = useState('#000000')
    const [bgColor, setBgColor] = useState('transparent')
    const [ownBlobs, setOwnBlobs] = useState([])
    const [initialized, setInitialized] = useState(false)

    const usesOuterBlobs = blobs !== undefined && setBlobs !== undefined
    const finalBlobs = usesOuterBlobs ? blobs : ownBlobs
    const setFinalBlobs = usesOuterBlobs ? setBlobs : setOwnBlobs

    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue || '')
        }
    }, [])

    useEffect(
        function () {
            if (initValue === value) {
                return
            }

            if (value === '') {
                setInitValue('')
                setInitialized(false)
                return
            }

            if (initialized) {
                return
            }
            setInitialized(true)

            async function update() {
                if (!value) return
                const processedValue = await processImages(value)
                setInitValue(processedValue)
            }

            update()
        },
        [value],
    )

    useEffect(function () {
        return function () {
            for (const blob of finalBlobs) {
                URL.revokeObjectURL(blob)
            }
        }
    }, [])

    useEffect(function () {
        function handleTabKey(e) {
            if (e.key !== 'Tab') return
            const selection = window.getSelection()
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0)
                const container = range.startContainer.parentNode

                if (!container.closest('ol') && !container.closest('ul')) {
                    return
                }

                e.preventDefault()

                let nestingLevel = 0
                let currentElement = container
                while (currentElement) {
                    if (
                        currentElement.tagName === 'OL' ||
                        currentElement.tagName === 'UL'
                    ) {
                        nestingLevel++
                    }
                    currentElement = currentElement.parentNode
                }

                if (!e.shiftKey && nestingLevel < 3) {
                    document.execCommand('indent')
                } else if (e.shiftKey && nestingLevel > 1) {
                    document.execCommand('outdent')
                }
            }
        }

        const editable = editableRef.current
        editable.addEventListener('keydown', handleTabKey)

        return () => {
            editable.removeEventListener('keydown', handleTabKey)
        }
    }, [])

    async function processImages(value) {
        const matches = []
        value.replace(
            /src="data:image\/[^;]+;base64,([^"]+)"/g,
            (match, base64) => {
                matches.push({ match, base64 })
                return match
            },
        )

        const promises = matches.map(async ({ match, base64 }) => {
            const blob = await fetch(`data:image/png;base64,${base64}`).then(
                (res) => res.blob(),
            )
            const blobURL = URL.createObjectURL(blob)
            setFinalBlobs((prevBlobs) => [...prevBlobs, blobURL])
            return { match, blobURL }
        })

        const replacements = await Promise.all(promises)

        let processedValue = value
        replacements.forEach(({ match, blobURL }) => {
            processedValue = processedValue.replace(match, `src="${blobURL}"`)
        })

        return processedValue
    }

    function saveSelection() {
        const selection = window.getSelection()
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0)
            setSavedSelection(range)
        }
    }

    function restoreSelection() {
        if (!savedSelection) return
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(savedSelection)
    }

    const handleCodeBlur = useCallback((e) =>
        onChange(sanitizeHTML(e.currentTarget.innerHTML)),
    )

    function handleCodeChange(v) {
        onChange(v)
        setInitValue(v)
    }

    function handleTextChange(v) {
        onChange(v.currentTarget.innerHTML)
    }

    function handleTextClick(e) {
        if (e.target.tagName === 'A') {
            setLinkUrl(e.target.href)
            setOpenInNewTab(e.target.target === '_blank')
        } else {
            setLinkUrl('')
            setOpenInNewTab(false)
        }
        if (e.target.tagName === 'FONT') {
            setColor(e.target.color)
        } else {
            setColor('#000000')
        }

        if (e.target.getAttribute('style')?.includes('background-color')) {
            const currBgColor = rgbToHex(
                window.getComputedStyle(e.target).backgroundColor,
            )
            setBgColor(currBgColor)
        } else {
            setBgColor('transparent')
        }
        // TODO
    }

    return (
        <div className="html-input">
            <div className="controls">
                <InlineStack wrap>
                    <Popover
                        ref={formatRef}
                        closeOnChildClick
                        renderActivator={(ref, listeners) => (
                            <div ref={ref}>
                                <Button
                                    {...listeners}
                                    small
                                    text={
                                        <InlineStack>
                                            <span>A</span>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <img
                                                    width={16}
                                                    height={16}
                                                    alt="toggle"
                                                    src={
                                                        ICONS.CHEVRON_RIGHT_WHITE
                                                    }
                                                />
                                            </div>
                                        </InlineStack>
                                    }
                                />
                            </div>
                        )}
                    >
                        {[
                            {
                                tag: 'h1',
                                title: 'Page Title (H1)',
                                className: 'editor-h1 h1',
                            },
                            {
                                tag: 'h2',
                                title: 'Section Header (H2)',
                                className: 'editor-h2 h2',
                            },
                            {
                                tag: 'h3',
                                title: 'Sub-Header (H3)',
                                className: 'editor-h3 h3',
                            },
                            {
                                tag: 'h4',
                                title: 'Heading (H4)',
                                className: 'editor-h4 h4',
                            },
                            {
                                tag: 'h5',
                                title: 'Heading (H5)',
                                className: 'editor-h5 h5',
                            },
                            {
                                tag: 'h6',
                                title: 'Heading (H6)',
                                className: 'editor-h6 h6',
                            },
                            {
                                tag: 'p',
                                title: 'Paragraph',
                                className: 'editor-p p',
                            },
                            {
                                tag: 'blockquote',
                                title: 'Blockquote',
                                className: 'editor-blockquote blockquote',
                            },
                        ].map(({ tag, title, className }) => {
                            return (
                                <div
                                    className={`editor-item ${className}`}
                                    role="button"
                                    key={tag}
                                    title={tag}
                                    onClick={(e) => {
                                        document.execCommand(
                                            'formatBlock',
                                            false,
                                            tag,
                                        )
                                    }}
                                >
                                    <div>{title}</div>
                                </div>
                            )
                        })}
                    </Popover>
                    <Button
                        onClick={() => document.execCommand('bold')}
                        small
                        text={<strong>B</strong>}
                    ></Button>
                    <Button
                        onClick={() => document.execCommand('italic')}
                        small
                        text={<em>I</em>}
                    ></Button>
                    <Button
                        onClick={() => document.execCommand('underline')}
                        small
                        text={<u>U</u>}
                    ></Button>
                    <Button
                        onClick={() =>
                            document.execCommand('insertOrderedList')
                        }
                        small
                        icon={ICONS.ORDERED_LIST_WHITE}
                    ></Button>
                    <Button
                        onClick={() =>
                            document.execCommand('insertUnorderedList')
                        }
                        small
                        icon={ICONS.UNORDERED_LIST_WHITE}
                    ></Button>
                    <Popover
                        closeOnChildClick
                        ref={alignRef}
                        renderActivator={(ref, listeners) => (
                            <div ref={ref}>
                                <Button
                                    small
                                    text={
                                        <InlineStack itemsCenter>
                                            <img
                                                width={16}
                                                height={16}
                                                alt="align"
                                                src={ICONS.ALIGN_LEFT_WHITE}
                                            />
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <img
                                                    width={16}
                                                    height={16}
                                                    alt="toggle"
                                                    src={
                                                        ICONS.CHEVRON_RIGHT_WHITE
                                                    }
                                                />
                                            </div>
                                        </InlineStack>
                                    }
                                    {...listeners}
                                    type="button"
                                />
                            </div>
                        )}
                    >
                        {[
                            { tag: 'justifyLeft', title: 'Left' },
                            { tag: 'justifyCenter', title: 'Center' },
                            { tag: 'justifyRight', title: 'Right' },
                        ].map(({ tag, title }) => {
                            return (
                                <div
                                    role="button"
                                    className="editor-item"
                                    key={tag}
                                    title={tag}
                                    onClick={() => {
                                        document.execCommand(tag)
                                    }}
                                >
                                    <div>{title}</div>
                                </div>
                            )
                        })}
                    </Popover>
                    <Popover
                        ref={linkRef}
                        renderActivator={(ref, listeners) => (
                            <div ref={ref}>
                                <Button
                                    small
                                    icon={ICONS.LINK_WHITE}
                                    {...listeners}
                                    type="button"
                                />
                            </div>
                        )}
                    >
                        <div className="editor-link">
                            <TextInput
                                type="text"
                                placeholder="URL"
                                id="link-url"
                                value={linkUrl}
                                onChange={(v) => setLinkUrl(v)}
                            />
                            <CheckboxInput
                                label="Open in new tab"
                                value={openInNewTab}
                                onChange={setOpenInNewTab}
                            />
                            <Button
                                onClick={() => {
                                    if (savedSelection.toString() === '') {
                                        return
                                    }
                                    restoreSelection()
                                    const TEMP_HREF = 'temp-href'
                                    document.execCommand(
                                        'createLink',
                                        false,
                                        TEMP_HREF,
                                    )
                                    if (openInNewTab) {
                                        document.querySelector(
                                            `a[href="${TEMP_HREF}"]`,
                                        ).target = '_blank'
                                    }
                                    document.querySelector(
                                        `a[href="${TEMP_HREF}"]`,
                                    ).href = linkUrl
                                    setLinkUrl('')
                                    setOpenInNewTab(false)
                                }}
                                text="Insert"
                            />
                        </div>
                    </Popover>
                    <div className="color-picker-container">
                        <div
                            style={{
                                color,
                            }}
                        >
                            a
                        </div>
                        <input
                            type="color"
                            value={color}
                            onInput={(e) => {
                                setColor(e.target.value)
                                document.execCommand(
                                    'foreColor',
                                    false,
                                    e.target.value,
                                )
                            }}
                        />
                    </div>
                    <div className="bg-color-picker-container">
                        <div
                            style={{
                                '--bg-input': bgColor,
                            }}
                        ></div>
                        <input
                            type="color"
                            value={bgColor}
                            onInput={(e) => {
                                setBgColor(e.target.value)
                                document.execCommand(
                                    'backColor',
                                    false,
                                    e.target.value,
                                )
                            }}
                        />
                    </div>

                    <FileInput
                        clearOnSelect
                        button
                        type="file"
                        accept="image/*"
                        onClick={saveSelection}
                        onChange={async (files) => {
                            const file = files?.[0]
                            if (!file) return
                            // if (file.size > 1024 * 10) {
                            //     alert('File size must be less than 10MB')
                            //     return
                            // }
                            // TTT
                            const dataUrl = await resizeImageFile(file)
                            const blobURL = dataURLToBlobUrl(dataUrl)
                            setFinalBlobs([...finalBlobs, blobURL])
                            if (!savedSelection) {
                                const range = document.createRange()
                                range.selectNodeContents(editableRef.current)
                                range.collapse(false)
                                window.getSelection().removeAllRanges()
                                window.getSelection().addRange(range)
                            } else {
                                restoreSelection()
                            }
                            document.execCommand('insertImage', false, blobURL)
                        }}
                    />
                    <Button
                        tiny
                        outline
                        text={showCode ? 'Show text' : '</>'}
                        alignRight
                        onClick={() => {
                            const inputEvent = new Event('input', {
                                bubbles: true,
                                cancelable: true,
                            })
                            editableRef.current.dispatchEvent(inputEvent)
                            setShowCode(!showCode)
                        }}
                    />
                </InlineStack>
            </div>
            <div className={showCode ? '' : 'aria-hidden'}>
                <TextInput
                    type="textarea"
                    id={id}
                    value={value}
                    onChange={handleCodeChange}
                    required={rest.required}
                    autoComplete={rest.autoComplete}
                    minLength={rest.minLength}
                    maxLength={rest.maxLength}
                    disabled={rest.disabled}
                    placeholder={rest.placeholder}
                />
            </div>

            <div
                ref={editableRef}
                // onBlur={handleCodeBlur}
                onClick={handleTextClick}
                onSelect={saveSelection}
                onInput={handleTextChange}
                className={`html-editable ${showCode ? 'aria-hidden' : ''}`}
                contentEditable
                id={id}
                required={rest.required}
                autoComplete={rest.autoComplete}
                minLength={rest.minLength}
                maxLength={rest.maxLength}
                disabled={rest.disabled}
                placeholder={rest.placeholder}
                dangerouslySetInnerHTML={{ __html: initValue }}
            ></div>
        </div>
    )
}
