import { ICONS } from '../../common/constants'
import useAuth from '../../common/hooks/useAuth'
import Button from '../../components/common/Button'
import InfoList from '../../components/common/InfoList'
import InlineStack from '../../components/common/InlineStack'
import MessageSection from '../../components/common/MessageSection'
import Tag from '../../components/common/Tag'
import Toggle from '../../components/common/Toggle'
import AddressesList from '../admin/addresses/AddressesList'

export default function ContactInfo({ userId, contactId, contactDetails }) {
    const isOwnProfile = !userId && !contactId
    const isContact = Boolean(contactId)
    return (
        <>
            <InlineStack spaceBetween>
                <h3 className="h6 text-subdued">Emails</h3>
                <Button
                    outline
                    tiny
                    alignRight
                    text="Edit"
                    icon={ICONS.EDIT_ACTIVE}
                    href={
                        isOwnProfile
                            ? '/profile/contact-details'
                            : `/${isContact ? 'contacts' : 'users'}/${
                                  userId || contactId
                              }/contact-details`
                    }
                />
            </InlineStack>
            {!contactDetails?.emails?.length ? (
                '-'
            ) : (
                <InfoList
                    rows={contactDetails.emails.map((p) => ({
                        icon: ICONS.MAIL_WHITE,
                        content: (
                            <>
                                {p.email || '-'}&nbsp;&nbsp;
                                <Tag noMargin outline color={'gray'}>
                                    {p.emailType}
                                </Tag>
                            </>
                        ),
                    }))}
                />
            )}

            <h3 className="h6 text-subdued">Phones</h3>
            {!contactDetails?.phones?.length ? (
                '-'
            ) : (
                <InfoList
                    rows={contactDetails.phones.map((p) => ({
                        icon: ICONS.PHONE_WHITE,
                        content: (
                            <>
                                {p.phone || '-'}&nbsp;&nbsp;
                                <Tag outline noMargin color={'gray'}>
                                    {p.phoneType}
                                </Tag>
                            </>
                        ),
                    }))}
                />
            )}

            <InlineStack spaceBetween>
                <h3 className="h6 text-subdued">Addresses</h3>
                <Button
                    outline
                    tiny
                    alignRight
                    text="Edit"
                    icon={ICONS.EDIT_ACTIVE}
                    href={
                        isOwnProfile
                            ? '/profile/addresses'
                            : `/${isContact ? 'contacts' : 'users'}/${
                                  userId || contactId
                              }/addresses`
                    }
                />
            </InlineStack>

            <AddressesList plain userId={userId} contactId={contactId} />

            <h3 className="h6 text-subdued">Other contact details</h3>
            <Toggle plain title="See details">
                <InfoList
                    rows={[
                        {
                            content: `Prefers ${
                                contactDetails?.prefersCommunication || 'all'
                            } comm.`,
                        },
                        {
                            content:
                                contactDetails?.bestTimeToCall?.from &&
                                contactDetails?.bestTimeToCall?.to
                                    ? `Best time to call: ${contactDetails?.bestTimeToCall?.from} - ${contactDetails?.bestTimeToCall?.to}`
                                    : '',
                        },
                        {
                            content:
                                contactDetails?.timeToNotCall?.from &&
                                contactDetails?.timeToNotCall?.to
                                    ? `Do not call: ${contactDetails?.timeToNotCall?.from} - ${contactDetails?.timeToNotCall?.to}`
                                    : '',
                        },
                        {
                            content: `Alternative first name: ${
                                contactDetails?.alternativeFirstName || '-'
                            }`,
                        },
                        {
                            content: `Alternative last name: ${
                                contactDetails?.alternativeLastName || '-'
                            }`,
                        },
                        {
                            content: `Nickname: ${
                                contactDetails?.nickname || '-'
                            }`,
                        },
                        {
                            content: `Facebook profile URL: ${
                                contactDetails?.facebook || '-'
                            }`,
                        },
                        {
                            content: `Instagram: ${
                                contactDetails?.instagram || '-'
                            }`,
                        },
                        {
                            content: `TikTok: ${contactDetails?.tiktok || '-'}`,
                        },
                        {
                            content: `YouTube: ${
                                contactDetails?.youtube || '-'
                            }`,
                        },
                        {
                            content: `Twitter: ${
                                contactDetails?.twitter || '-'
                            }`,
                        },
                        {
                            content: `Pinterest: ${
                                contactDetails?.pinterest || '-'
                            }`,
                        },
                        {
                            content: `Snapchat: ${
                                contactDetails?.snapchat || '-'
                            }`,
                        },
                        {
                            content: `LinkedIn: ${
                                contactDetails?.linkedin || '-'
                            }`,
                        },
                        {
                            content: `Nextdoor: ${
                                contactDetails?.nextdoor || '-'
                            }`,
                        },
                        {
                            content: `GitHub: ${contactDetails?.github || '-'}`,
                        },
                        {
                            content: `Website: ${
                                contactDetails?.website || '-'
                            }`,
                        },
                        {
                            content: `Marketing opt-in: ${
                                contactDetails?.marketingOptIn ? 'Yes' : 'No'
                            }`,
                        },
                    ]}
                />
            </Toggle>
            <br />
        </>
    )
}
