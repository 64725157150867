import { useEffect } from 'react'

function CheckboxBase({
    id,
    label,
    checked,
    onChange,
    onClick,
    disabled,
    required,
    info,
    name,
    hideLabel,
}) {
    function handleClick() {
        if (onClick) {
            onClick()
        }
        if (disabled) return
        if (!label) return
        onChange(!checked)
    }

    return (
        <div
            className={`checkbox ${checked ? 'checked' : ''}`}
            onClick={hideLabel ? handleClick : undefined}
        >
            <input
                name={name}
                checked={checked}
                type="checkbox"
                id={id}
                onChange={() => {}}
                disabled={disabled}
                required={required || undefined}
            />
            {!hideLabel && (
                <label onClick={handleClick} htmlFor={id}>
                    {label}
                </label>
            )}
            {info ? <small className="info">{info}</small> : null}
        </div>
    )
}

export default function CheckboxInput({
    value,
    onChange,
    defaultValue,
    noBr,
    ...rest
}) {
    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue || false)
        }
    }, [])

    function handleChange(v) {
        onChange(v)
    }

    return (
        <>
            <CheckboxBase
                checked={Boolean(value)}
                onChange={handleChange}
                {...rest}
            />
            {!noBr && <br />}
        </>
    )
}
