import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import DataFormNew from '../../../components/common/DataFormNew'
import { cleanSlugInput, setDataUrls } from '../../../common/helpers'
import SectionContainer from '../../../components/common/SectionContainer'

export default function EditPage() {
    const { pageId } = useParams()
    const [viewLoading, setViewLoading] = useState(false)
    const setToast = useToast()
    const navigate = useNavigate()

    async function onViewPage() {
        setViewLoading(true)
        const url = `/v1/pages/admin/${pageId}`
        const { responseData, error } = await fetchAPI(url)
        setViewLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        navigate(`/pages/${responseData.slug}`)
    }

    const inputs = [
        {
            key: 'info',
            type: 'jsx',
            render: () => (
                <Button
                    text="View page"
                    isLoading={viewLoading}
                    small
                    outline
                    alignRight
                    onClick={onViewPage}
                    icon={ICONS.EYE_ACTIVE}
                />
            ),
        },
        {
            key: 'slug',
            label: 'Slug',
            required: true,
            autoComplete: 'new-password',
            shouldHide: (data) => data.appScreen,
            transformValue: (v) => cleanSlugInput(v),
        },
        {
            key: 'title',
            label: 'Title',
            required: true,
            autoComplete: 'new-password',
        },
        // {
        //     key: 'isHtml',
        //     label: 'HTML',
        //     type: 'checkbox',
        // },
        {
            key: 'content',
            label: 'Content',
            type: 'html',
            id: 'form-description',
        },
        {
            key: 'sassCode',
            label: 'Sass',
            type: 'textarea',
            rows: 16,
            shouldHide: (data) => !data.isHtml,
        },
        {
            key: 'tags',
            type: 'liveSearchAddItems',
            label: 'Tags',
            canAddNewItem: true,
            url: '/v1/tags?resource=Page',
            getItemText: (item) => item,
            fields: [
                {
                    column: 1,
                    getValue: (item) => item,
                },
            ],
        },
        {
            key: 'isPublic',
            label: 'Public page',
            type: 'checkbox',
            info: 'Public pages are accessible to anyone, even if they are not logged in.',
        },
        {
            key: 'isPublished',
            label: 'Publish page',
            type: 'checkbox',
        },
        {
            key: 'showOnClientMenu',
            label: 'Show on client Menu',
            type: 'checkbox',
        },
        {
            key: 'showOnTeamMenu',
            label: 'Show on team Menu',
            type: 'checkbox',
        },
        {
            key: 'isGlossary',
            label: 'Group with alphabet',
            type: 'checkbox',
            info: 'Assign pages to it, and they will appear in glossary format in it.',
            shouldHide: (data) => data.appScreen,
        },
        {
            key: 'parentPageId',
            label: 'Parent page',
            type: 'liveSearch',
            shouldHide: (data) => data.appScreen,
            getResultValue: (item) => item.title,
            url: '/v1/pages?pageType=glossary',
            getItemsFromResults: (data) => data?.results || [],
            limit: 20,
            infoBottom:
                'Slug will be under the parent page path. If parent is type glossary, this page will be shown in glossary format in it.',
        },
    ]

    function mapItemToData(item) {
        return {
            slug: item.slug,
            title: item.title,
            isGlossary: item.pageType === 'glossary',
            content: item.content,
            tags: item.tags,
            isPublic: item.isPublic,
            isPublished: item.isPublished,
            isHtml: item.isHtml,
            sassCode: item.sassCode,
            showOnClientMenu: item.showOnClientMenu,
            showOnTeamMenu: item.showOnTeamMenu,
            parentPageId: item.parentPage?.title,
        }
    }

    async function getBody(data, item) {
        const body = { ...data }
        if (data.isGlossary) {
            body.pageType = 'glossary'
        } else {
            body.pageType = 'default'
        }
        delete body.isGlossary

        if (data.parentPageId === item.parentPage?.title) {
            delete body.parentPageId
        }

        const finalContent = await setDataUrls(data.content)
        body.content = finalContent

        return body
    }

    return (
        <>
            <SectionContainer>
                <DataFormNew
                    url={`/v1/pages/admin/${pageId}`}
                    method="PATCH"
                    submitText="Save"
                    submitToast="Saved"
                    inputs={inputs}
                    mapItemToData={mapItemToData}
                    getBody={getBody}
                    mainButton
                    mutationRegexes={[/\/v1\/translations/, /\/v1\/pages/]}
                />
            </SectionContainer>
        </>
    )
}
