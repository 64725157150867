import { useState } from 'react'
import HtmlInput from '../../components/common/data-form/HtmlInput'
import Button from '../../components/common/Button'
import Input from '../../components/common/data-form/Input'
import InlineStack from '../../components/common/InlineStack'
import HorizontalScroll from '../../components/common/HorizontalScroll'
import ContactThumbnail from './clients/ContactThumbnail'
import ResourceList from '../../components/admin/ResourceList'
import HtmlEditor from '../../components/common/HtmlEditor'

export default function Dev() {
    const [html, setHtml] = useState('')
    return <HtmlInput value={html} onChange={setHtml} />
}
