import { useEffect, useRef, useState } from 'react'
import { setSearchParams } from '../../common/helpers'
import { useLocation } from 'react-router-dom'

function updateParamsTitle(title, checkHref) {
    if (checkHref && checkHref !== window.location.href) return
    const searchParams = new URLSearchParams(window.location.search)

    const newParams = {}
    for (const [key, value] of searchParams.entries()) {
        newParams[key] = value
    }

    newParams.t_title = title
    setSearchParams(newParams)
}

function Toggle({
    title,
    children,
    headerChildrenHtml,
    open,
    small,
    heading = 'h4',
    gold,
    plain,
}) {
    const location = useLocation()
    const elRef = useRef(null)

    const escapedTitle = (title || '').replace(/,/g, '_')
    let finalOpen = open

    const rememberToggles =
        localStorage.getItem('forget_toggles_state') === 'false'
    const savedOpen = localStorage
        .getItem('open_toggles')
        ?.split(',')
        ?.includes(escapedTitle)
    const savedClosed = localStorage
        .getItem('closed_toggles')
        ?.split(',')
        ?.includes(escapedTitle)

    if (rememberToggles) {
        if (savedOpen && !savedClosed) {
            finalOpen = true
        } else if (savedClosed && !savedOpen) {
            finalOpen = false
        }
    }

    if (new URLSearchParams(window.location.search).get('t_title') === title) {
        finalOpen = true
    }
    const openRef = useRef(finalOpen)

    useEffect(
        function () {
            const shouldScrollTo =
                openRef.current &&
                new URLSearchParams(window.location.search).get('t_title') ===
                    title

            let observer
            if (shouldScrollTo) {
                observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                        if (mutation.addedNodes.length) {
                            elRef.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            })
                            observer.disconnect()
                        }
                    })
                })

                observer.observe(elRef.current, {
                    childList: true,
                    subtree: true,
                })
            }

            return function () {
                if (observer) {
                    observer.disconnect()
                }
            }
        },
        [openRef?.current],
    )

    useEffect(function () {
        return function () {
            if (
                new URLSearchParams(window.location.search).get('t_title') ===
                title
            ) {
                updateParamsTitle(undefined, location.href)
            }
        }
    }, [])

    function handleToggle() {
        if (rememberToggles) {
            if (!elRef.current.open) {
                if (savedClosed) {
                    localStorage.setItem(
                        'closed_toggles',
                        localStorage
                            .getItem('closed_toggles')
                            .split(',')
                            .filter((t) => t !== escapedTitle)
                            .join(','),
                    )
                }
                if (!savedOpen) {
                    localStorage.setItem(
                        'open_toggles',
                        [
                            ...(
                                localStorage.getItem('open_toggles') || ''
                            ).split(','),
                            escapedTitle,
                        ].join(','),
                    )
                }
            } else {
                if (savedOpen) {
                    localStorage.setItem(
                        'open_toggles',
                        localStorage
                            .getItem('open_toggles')
                            .split(',')
                            .filter((t) => t !== escapedTitle)
                            .join(','),
                    )
                }
                if (!savedClosed) {
                    localStorage.setItem(
                        'closed_toggles',
                        [
                            ...(
                                localStorage.getItem('closed_toggles') || ''
                            ).split(','),
                            escapedTitle,
                        ].join(','),
                    )
                }
            }
        }

        if (!elRef.current.open) {
            updateParamsTitle(title)
        } else {
            if (
                new URLSearchParams(window.location.search).get('t_title') ===
                title
            ) {
                updateParamsTitle(undefined)

                if (
                    new URLSearchParams(window.location.search).get(
                        'f_url_key',
                    ) === title
                ) {
                    const paramsObj = Object.fromEntries(
                        new URLSearchParams(window.location.search).entries(),
                    )
                    const paramsToDelete = Object.keys(paramsObj).filter(
                        (key) => key.startsWith('f_'),
                    )
                    for (const key of paramsToDelete) {
                        delete paramsObj[key]
                    }
                    setSearchParams(paramsObj)
                }
            }
        }
    }

    let headingHtml
    if (heading === 'h2') {
        headingHtml = <h2>{title}</h2>
    } else {
        headingHtml = <h4>{title}</h4>
    }

    let className = 'toggle'
    if (small) {
        className += ' toggle-sm'
    }
    if (gold) {
        className += ' gold'
    }
    if (plain) {
        className += ' plain'
    }

    return (
        <details ref={elRef} open={openRef.current} className={className}>
            <summary onClick={handleToggle}>
                <div className="toggle-header">
                    <div className="toggle-header-heading">{headingHtml}</div>
                    <div className="toggle-header-content">
                        {headerChildrenHtml}
                    </div>

                    <div className="toggle-header-icon">
                        <img
                            alt="collapse"
                            src="/img/icons/chevron-left-white.svg"
                        ></img>
                    </div>
                </div>
            </summary>
            <div
                className="toggle-body"
                onClick={() => updateParamsTitle(title, location.href)}
            >
                {children}
            </div>
        </details>
    )
}

export default Toggle
