import { useEffect, useState } from 'react'
import Tag from '../Tag'
import Button from '../Button'
import LiveSearch from './LiveSearch'

export default function LiveSearchInput({ value, onChange, ...input }) {
    function onItemClick(item) {
        onChange(item.id)
    }

    return (
        <LiveSearch onItemClick={onItemClick} {...input} defaultLabel={value} />
    )
}
