import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    CONNECTED_ACCOUNT_PROPERTY_TYPE_NAMES,
    CONNECTED_ACCOUNT_SERVICE_NAMES,
    MONTHS,
} from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import Toggle from '../../../components/common/Toggle'
import MessageSection from '../../../components/common/MessageSection'
import PremiumBanner from '../../admin/app-billing/PremiumBanner'
import MainButton from '../../../components/admin/MainButton'
import { getProductTagAvailabilityGroups } from '../../../common/helpers'
import useModal from '../../../common/hooks/useModal'
import TextModalContent from '../../../components/common/TextModalContent'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import InlineStack from '../../../components/common/InlineStack'
import BlockStack from '../../../components/common/BlockStack'
import Tag from '../../../components/common/Tag'
import SelectInput from '../../../components/common/data-form/SelectInput'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

const PRODUCT_DESC = `By default, the app automatically selects and posts ANY and ALL products from the designated sites. This includes the user's own site if they are a premium user, as well as aCo wholesale and XO Studio. When posting from XO Studio or aCo, the app may include all products with similar tags, unless the user specifically chooses a tag that filters and includes only their own products.

To further refine the product selection process, Shopify product tags can be utilized. These tags enable users to narrow down which products are posted overall. In addition, premium users have the advantage of automatically adjusting the tags used to filter products based on the current month. This ensures that the posted products remain relevant and aligned with the user's marketing strategies.

You could add an info icon at the beginning and just show a sentence or two then add Learn more > with a show / hide expansion so clicking learn more shows the full text then add collapse or hide tooltip 

When that’s clicked it puts everything inside a info icon tooltip that moves up next to the label or just collapses the text back to a sentence or two with the learn more button. Either way this works to save space or show this helpful information as needed without completely hiding it in a tooltip.`

const ARTICLE_DESC = `The app offers the capability to schedule and post blog posts on a daily basis, allowing users to determine the frequency between 1 to 10 times a day. However, the availability of content on the blog may impose restrictions on this feature. The system is intelligently designed to prevent the posting of identical content on social media within a week. It also aims to minimize the repetition and redundancy of posts within a certain threshold, ensuring that the social media feeds do not become monotonous or overwhelming for followers.

Nevertheless, reposting products and reusing marketing content is encouraged, as studies have demonstrated that it often takes viewers more than five exposures to feel familiar with a brand and its advertisements. This is why television commercials are repeated. However, it's crucial to strike a delicate balance and avoid becoming repetitive or annoying on social media platforms.

When it comes to blog posts, users can select the featured image, titles, descriptions, and promotional text from the XO Studio blog. These selections are based on the tags chosen as filters, which can include or exclude specific content. Premium users have the added benefit of including their own site's blog and automatically adjusting the filters based on the month. This enables subscribers of this feature to incorporate marketing content and engaging posts into their daily posting schedule, enhancing their social media presence.`

const initOwnProductsData = {
    propertyDbIds: [],
    frequency: '0',
    shop: 'all',
    excludeTags: '',
    includeTags: '',
    enableHashtags: false,
    includeTagsByMonth: MONTHS.map((m) => m.abbreviation.toLowerCase()).reduce(
        (a, b) => ({ ...a, [b]: '' }),
        {},
    ),
    excludeTagsByMonth: MONTHS.map((m) => m.abbreviation.toLowerCase()).reduce(
        (a, b) => ({ ...a, [b]: '' }),
        {},
    ),
}

const initArticlesData = {
    ...initOwnProductsData,
    shop: '',
}

export default function CreatePostPlan() {
    const [postType, setPostType] = useState('')
    const [error, setError] = useError()
    const [actionLoading, setActionLoading] = useState(false)
    const navigate = useNavigate()
    const [tagsLoading, setTagsLoading] = useState(false)
    const [previewData, setPreviewData] = useState(null)
    const [previewLoading, setPreviewLoading] = useState(false)
    const { setModal } = useModal()

    const setToast = useToast()
    const auth = useAuth()

    const { accounts, accountsError, accountsLoading } = useData(
        `/v1/connected-accounts?userId=${auth.user.id}`,
        'accounts',
    )

    const { shops, shopsError, shopsLoading } = useData(
        `/v1/wholesale/shops?userId=${auth.user.id}&fields=name,fullName`,
        'shops',
    )

    const [data, setData] = useState(initOwnProductsData)

    function updateData(newData) {
        setData(newData)
        updatePreview(newData)
    }

    function handlePostTypeSet(type) {
        updateData(
            type === 'ownProducts' ? initOwnProductsData : initArticlesData,
        )
        setPostType(type)
    }

    function onTagAdd(tag, group) {
        let tags
        let dataField
        switch (group) {
            case 'include':
                tags = data.includeTags
                dataField = 'includeTags'
                break
            case 'exclude':
                tags = data.excludeTags
                dataField = 'excludeTags'
                break
            default:
                return
        }
        tags = tags.trim().toLowerCase().split(',')
        tags.push(tag.toLowerCase())
        tags = tags.filter((t) => t).join(',')
        updateData({ ...data, [dataField]: tags })
        setModal(null)
    }

    function onTagRemove(tag, group) {
        let tags
        let dataField
        switch (group) {
            case 'include':
                tags = data.includeTags
                dataField = 'includeTags'
                break
            case 'exclude':
                tags = data.excludeTags
                dataField = 'excludeTags'
                break
            default:
                return
        }
        tags = tags.trim().toLowerCase().split(',')
        tags = tags.filter((t) => t !== tag.toLowerCase()).join(',')
        updateData({ ...data, [dataField]: tags })
        setModal(null)
    }

    async function showTagsModal(group) {
        if (!group) return

        let usedTags
        switch (group) {
            case 'include':
                usedTags = data.includeTags
                break
            case 'exclude':
                usedTags = data.excludeTags
                break
            default:
                return
        }
        usedTags = usedTags.trim().split(',')

        setTagsLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/wholesale/product-tags?shopName=${data.shop}`,
        )
        setTagsLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        const { unavailableTags, availableTags, nonExistingTags } =
            getProductTagAvailabilityGroups(usedTags, responseData.tags)

        setModal(
            <TextModalContent
                content={
                    <div>
                        <h3 className="m-0">Available</h3>
                        {availableTags
                            ? availableTags.map((tag) => (
                                  <span key={tag}>
                                      <Tag
                                          onClick={() => onTagAdd(tag, group)}
                                          outline
                                      >
                                          {tag}
                                      </Tag>
                                      &nbsp;
                                  </span>
                              ))
                            : 'None'}

                        <Break />
                        <h3 className="m-0">Used</h3>
                        {unavailableTags.length
                            ? unavailableTags.map((tag) => (
                                  <span key={tag}>
                                      <Tag
                                          color="primary"
                                          onClick={() =>
                                              onTagRemove(tag, group)
                                          }
                                      >
                                          {tag}
                                      </Tag>
                                      &nbsp;
                                  </span>
                              ))
                            : 'None'}

                        <Break />
                        <h3 className="m-0">Not found in this store</h3>
                        {nonExistingTags.length
                            ? nonExistingTags.map((tag) => (
                                  <span key={tag}>
                                      <Tag color={'primary'}>{tag}</Tag>
                                      &nbsp;
                                  </span>
                              ))
                            : 'None'}
                    </div>
                }
            />,
            'Tags',
        )
    }

    async function updatePreview(newData) {
        if (!postType) return
        setPreviewLoading(true)

        const body = {
            userId: auth.user.id,
            settings: {
                shop: newData.shop,
                excludeTags: newData.excludeTags,
                includeTags: newData.includeTags,
                enableHashtags: newData.enableHashtags,
                includeTagsByMonth: newData.includeTagsByMonth,
                excludeTagsByMonth: newData.excludeTagsByMonth,
            },
            postType,
        }

        const { responseData, error } = await fetchAPI(
            `/v1/social-posts/plans/preview`,
            body,
            'POST',
        )
        setPreviewLoading(false)
        if (error) {
            setToast(error, 'alert')
            setPreviewData(null)
            return
        }

        setPreviewData(responseData)
    }

    async function onSubmit(e) {
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        const body = {
            userId: auth.user.id,
            propertyIds: data.propertyDbIds,
            settings: {
                frequency: Number(data.frequency),
                shop: data.shop,
                excludeTags: data.excludeTags,
                includeTags: data.includeTags,
                enableHashtags: data.enableHashtags,
                includeTagsByMonth: data.includeTagsByMonth,
                excludeTagsByMonth: data.excludeTagsByMonth,
            },
            postType,
        }

        setActionLoading(true)
        const { error } = await fetchAPI('/v1/social-posts/plans', body, 'POST')
        setActionLoading(false)
        if (error) {
            setError(error)
            return
        }
        if (Number(data.frequency) === 0 || !data.propertyDbIds.length) {
            setToast(
                'Created, will not post because frequency is 0 or no accounts are selected',
                'warning',
            )
        } else {
            setToast('Posting settings are saved')
        }
        navigate('/content#shop_post_plans__')
    }

    if (accountsLoading || shopsLoading) {
        return <Spinner />
    }
    if (accountsError || shopsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{accountsError || shopsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!accounts.length) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    No accounts found, please connect one first.
                </ErrorMessage>
            </SectionContainer>
        )
    }

    if (!shops.length) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    No shops found, please connect one in&nbsp;
                    <Link to="/shop#shop_settings__">Shop Settings</Link>.
                </ErrorMessage>
            </SectionContainer>
        )
    }

    const properties = accounts
        .map((account) =>
            account.properties.map((property) => ({
                ...property,
                account,
            })),
        )
        .flat()

    if (!postType) {
        return (
            <SectionContainer>
                <div className="form">
                    <div className="form-group">
                        <label htmlFor="post-type">Post type</label>
                        <SelectInput
                            required
                            value={postType}
                            id="post-type"
                            onChange={(v) => handlePostTypeSet(v)}
                            options={[
                                { value: '', label: 'Select a post type' },
                                { value: 'ownProducts', label: 'Products' },
                                {
                                    value: 'articles',
                                    label: 'Blog posts (Beta)',
                                },
                            ]}
                        />
                    </div>
                </div>

                <div>
                    <h3>Post Products</h3>
                    {PRODUCT_DESC.split('\n')[0]}
                    <details>
                        <summary>Read more.</summary>
                        <pre>{PRODUCT_DESC.split('\n').slice(1)}</pre>
                    </details>
                </div>
                <div>
                    <h3>
                        Post Blog posts <Tag outline>Beta</Tag>
                    </h3>
                    {ARTICLE_DESC.split('\n')[0]}
                    <details>
                        <summary>Read more.</summary>
                        <pre>{ARTICLE_DESC.split('\n').slice(1)}</pre>
                    </details>
                </div>
            </SectionContainer>
        )
    }

    return (
        <>
            {!auth.isPremiumUser && (
                <SectionContainer>
                    <MessageSection
                        type="warning"
                        small
                        title="Temporary free trial"
                    >
                        <ul>
                            <li>
                                <a
                                    href="https://app.aco.digital/pages/terms-of-service"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Read terms of use
                                </a>
                                &nbsp;and
                            </li>
                            <li>
                                <details>
                                    <summary>Free trial terms</summary>
                                    <div>
                                        This feature is provided for your use
                                        for free for an undefined period of
                                        time. Agency Couture may at any time
                                        without notice revoke access to the
                                        feature or request payment for use of
                                        software as a service. At which time it
                                        will be your choice to pay for the tool
                                        or discontinue use and potentially lose
                                        any data. We make no guarantees to data,
                                        backups, or loss of data. This feature
                                        is in beta testing and use at your own
                                        risk. Even after beta testing ends
                                        Agency Couture and its successors will
                                        not be held liable or responsible for
                                        your use of the tools, features or
                                        software or loss of data. Agency Couture
                                        reserves the right to backup, repurpose,
                                        or utilize data and usage notes for
                                        commercial use, resale, and financial
                                        gain. Under no circumstances will you,
                                        as a user have right to any intellectual
                                        property, idea submissions, client data
                                        or usage notes for the software. Any
                                        attempt to misuse the software or lack
                                        of payment for other services or
                                        cancellation may result in an immediate
                                        block or ban from the software. Any
                                        attempt to steal the software code,
                                        intellectual property or copyright is
                                        strictly prohibited and may result in
                                        legal action, fines and other recourse.
                                        Passwords stored in the vault are
                                        intended for client-agency usage and may
                                        be shared with the Agency Couture team.
                                        Use of this feature is at your own risk
                                        and you agree to indemnify Agency
                                        Couture, its staff, successor and
                                        affiliates from all claims, loss of
                                        data, loss of privacy, errors or
                                        negligence related to your use of all
                                        software as a service connected with
                                        your use of this software.
                                        <br />
                                        By using this software you agree
                                        explicitly to all of these terms and any
                                        changes with notice made only via this
                                        application. Your only recourse if you
                                        disagree with these terms is to
                                        discontinue usage.
                                    </div>
                                </details>
                            </li>
                        </ul>
                    </MessageSection>
                </SectionContainer>
            )}

            {postType === 'articles' ? (
                <SectionContainer>
                    <MessageSection
                        type="warning"
                        small
                        title="Blog posts (Beta) - update your access token"
                    >
                        <div>
                            For blog posts to be posted you must make sure you
                            have enabled the <code>read_content</code> access
                            scope. Go to your Shopify admin dashboard, Settings
                            / Apps and sales channels / Develop apps / Click on
                            the app you use to integrate / Configuration / Edit
                            / Enable the <code>read_content</code> access scope
                            / Save.
                            {/* https://shopify.com/admin/settings/apps/development */}
                        </div>
                    </MessageSection>
                </SectionContainer>
            ) : null}

            <SectionContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}

                <form onSubmit={onSubmit}>
                    <label htmlFor="form-properties">
                        Properties to post to
                    </label>
                    <ResourceList
                        getSelectedItemText={(item) => item.name}
                        items={properties}
                        isSelectable
                        selectedIdField={'_id'}
                        preselectedIds={data.propertyDbIds}
                        onSelectedItemsChange={(items) =>
                            updateData({
                                ...data,
                                propertyDbIds: items,
                            })
                        }
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <>
                                        <div>
                                            {`${item.name} | ${
                                                CONNECTED_ACCOUNT_PROPERTY_TYPE_NAMES[
                                                    item.propertyType
                                                ]
                                            }
                                        `}
                                        </div>
                                        <small className="text-subdued">
                                            {`${item.account.accountName} | ${
                                                CONNECTED_ACCOUNT_SERVICE_NAMES[
                                                    item.account.service
                                                ]
                                            }
                                        `}
                                        </small>
                                    </>
                                ),
                                getClassName: () => 'title',
                            },
                        ]}
                    />
                    <Break />

                    <label htmlFor="form-shop">
                        {`Shops to post ${
                            postType === 'ownProducts' ? 'products' : 'articles'
                        } from`}
                    </label>

                    <SelectInput
                        required
                        value={data.shop}
                        id="form-shop"
                        onChange={(v) => updateData({ ...data, shop: v })}
                        options={
                            postType === 'ownProducts'
                                ? [
                                      {
                                          value: 'all',
                                          label: 'All available shops',
                                      },
                                      ...shops.map((shop) => ({
                                          value: shop.name,
                                          label: shop.fullName,
                                      })),
                                  ]
                                : [
                                      { value: '', label: 'Select a shop' },
                                      ...shops.map((shop) => ({
                                          value: shop.name,
                                          label: shop.fullName,
                                      })),
                                  ]
                        }
                    />

                    <InlineStack flushMargins spaceBetween itemsCenter>
                        <label htmlFor="form-tags-include">
                            Post only items with these tags
                        </label>
                        {postType === 'ownProducts' ? (
                            <Button
                                text="Show tags"
                                tiny
                                outline
                                alignRight
                                onClick={() => showTagsModal('include')}
                                isLoading={tagsLoading}
                                disabled={data.shop === 'all'}
                            />
                        ) : null}
                    </InlineStack>
                    <BlockStack gap={'tiny'}>
                        <small className="text-subdued">
                            Comma separated, leave empty to post from all items.
                            This field is not case sensitive.
                        </small>
                        <TextInput
                            type="textarea"
                            id="form-tags-include"
                            value={data.includeTags || ''}
                            onChange={(v) =>
                                setData({ ...data, includeTags: v })
                            }
                            autoComplete="off"
                        />
                    </BlockStack>

                    <InlineStack flushMargins spaceBetween itemsCenter>
                        <label htmlFor="form-tags-exclude">
                            Never post items with these tags
                        </label>
                        {postType === 'ownProducts' ? (
                            <Button
                                text="Show tags"
                                tiny
                                outline
                                alignRight
                                onClick={() => showTagsModal('exclude')}
                                isLoading={tagsLoading}
                                disabled={data.shop === 'all'}
                            />
                        ) : null}
                    </InlineStack>
                    <BlockStack gap={'tiny'}>
                        <small className="text-subdued">
                            {`Comma separated. This field is not case sensitive. Ay
                        items with the 'aco_app_exclude' tag will also be
                        excluded from posting.`}
                        </small>
                        <TextInput
                            type="textarea"
                            id="form-tags-exclude"
                            value={data.excludeTags || ''}
                            onChange={(v) =>
                                setData({ ...data, excludeTags: v })
                            }
                            autoComplete="off"
                        />
                    </BlockStack>

                    <Toggle small title="Tags by month">
                        {auth.isPremiumUser ? (
                            <>
                                <small className="text-subdued">
                                    Comma separated. Not case sensitive.
                                </small>
                                <Break />
                                {MONTHS.map((month) => (
                                    <div key={month.abbreviation}>
                                        <BlockStack flushMargins gap={'tiny'}>
                                            <label
                                                htmlFor={`form-tags-include-${month.abbreviation}`}
                                            >
                                                {`${month.name}: Post only items with
                                            these tags`}
                                            </label>

                                            <TextInput
                                                type="textarea"
                                                id={`form-tags-include-${month.abbreviation}`}
                                                value={
                                                    data.includeTagsByMonth[
                                                        month.abbreviation.toLowerCase()
                                                    ]
                                                }
                                                onChange={(v) =>
                                                    updateData({
                                                        ...data,
                                                        includeTagsByMonth: {
                                                            ...data.includeTagsByMonth,
                                                            [month.abbreviation.toLowerCase()]:
                                                                v,
                                                        },
                                                    })
                                                }
                                                rows={1}
                                                autoComplete="off"
                                            />
                                        </BlockStack>

                                        <BlockStack flushMargins gap={'tiny'}>
                                            <label
                                                htmlFor={`form-tags-exclude-${month.abbreviation}`}
                                            >
                                                {`${month.name}: Never post items with
                                            these tags`}
                                            </label>

                                            <TextInput
                                                type="textarea"
                                                id={`form-tags-exclude-${month.abbreviation}`}
                                                value={
                                                    data.excludeTagsByMonth[
                                                        month.abbreviation.toLowerCase()
                                                    ]
                                                }
                                                onChange={(v) =>
                                                    updateData({
                                                        ...data,
                                                        excludeTagsByMonth: {
                                                            ...data.excludeTagsByMonth,
                                                            [month.abbreviation.toLowerCase()]:
                                                                v,
                                                        },
                                                    })
                                                }
                                                rows={1}
                                                autoComplete="off"
                                            />
                                        </BlockStack>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <PremiumBanner />
                        )}
                    </Toggle>

                    <Break />

                    <CheckboxInput
                        id="form-use-hashtags"
                        label="Enable hashtags"
                        value={data.enableHashtags}
                        onChange={(enableHashtags) =>
                            updateData({
                                ...data,
                                enableHashtags,
                            })
                        }
                    />

                    <BlockStack flushMargins gap={'tiny'}>
                        <label htmlFor={`form-frequency`}>
                            {`How often to post to each property (times/day)`}
                        </label>

                        <TextInput
                            required
                            type="number"
                            min="0"
                            max="10"
                            id={`form-frequency`}
                            value={data.frequency}
                            onChange={(v) =>
                                updateData({
                                    ...data,
                                    frequency: v,
                                })
                            }
                            autoComplete="off"
                        />
                    </BlockStack>

                    <MessageSection
                        small
                        type="info"
                        title={
                            <>
                                Preview&nbsp;
                                <Tag outline>Beta</Tag>
                            </>
                        }
                    >
                        {previewLoading ? <Spinner /> : null}
                        {previewData && !previewLoading ? (
                            <>
                                <div>
                                    Total items found: {previewData.count}
                                </div>
                                <hr />
                                <pre>{previewData.message}</pre>
                                <img src={previewData.imageUrl} alt="preview" />
                            </>
                        ) : null}
                    </MessageSection>

                    <Break />

                    <Button
                        text="Save"
                        isLoading={actionLoading}
                        type="submit"
                    />

                    <MainButton
                        disabled={actionLoading}
                        functionality="SAVE"
                        loading={actionLoading}
                    />
                </form>
            </SectionContainer>
        </>
    )
}
