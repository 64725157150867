import { useState } from 'react'
import { useStore } from '../../common/Context'
import Button from './Button'
import TextInput from './data-form/TextInput'
import LiveSearchAddItemsInput from './data-form/LiveSearchAddItemsInput'

function InputModalContent({
    onCancel,
    onConfirm,
    label, // TODO
    type,
    confirmText,
    defaultValue,

    items,
}) {
    const [state, dispatch] = useStore()
    const [data, setData] = useState({ text: defaultValue || '', items: [] })

    const handleCancel = () => {
        dispatch({ type: 'CLOSE_MODAL' })
        onCancel && onCancel()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onConfirm(data)
    }

    let inputHtml
    if (type === 'liveSearchAddItems') {
        inputHtml = (
            <LiveSearchAddItemsInput
                defaultValue={defaultValue}
                loadMoreButton
                canAddNewItem={false}
                value={data.items}
                onChange={(v) => setData({ ...data, items: v })}
                items={items}
                getItemText={(item) => item}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item,
                    },
                ]}
            />
        )
    } else {
        inputHtml = (
            <TextInput
                value={data.text || ''}
                onChange={(v) => setData({ ...data, text: v })}
                type={type || 'text'}
                autoComplete="off"
                id="modal-input"
            />
        )
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="modal-body">{inputHtml}</div>
                <div className="modal-buttons">
                    <Button
                        small
                        destructive
                        link
                        onClick={handleCancel}
                        text={'Cancel'}
                        disabled={state.isModalLoading}
                    />
                    <Button
                        small
                        type="submit"
                        isLoading={state.isModalLoading}
                        text={confirmText || 'Submit'}
                    />
                </div>
            </form>
        </>
    )
}

export default InputModalContent
