import { useEffect, useState } from 'react'
import useError from '../../common/hooks/useError'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useToast from '../../common/hooks/useToast'
import useMatchMutate from '../../common/hooks/useMatchMutate'
import fetchAPI from '../../common/fetchAPI'
import Button from './Button'
import Toggle from './Toggle'
import MainButton from '../admin/MainButton'
import Input from './data-form/Input'
import MessageSection from './MessageSection'
import Spinner from './Spinner'
import ErrorMessage from './ErrorMessage'

function compareData(data1, data2) {
    for (const [k, v] of Object.entries(data1)) {
        if (Array.isArray(v)) {
            if (v.length !== data2[k].length) {
                return false
            }
            for (const [i, item] of v.entries()) {
                if (item !== data2[k][i]) {
                    return false
                }
            }
        } else {
            if (data2[k] !== v) {
                return false
            }
        }
    }
    return true
}

function populateUrlVariables(url, item) {
    const urlParts = url.split('/')
    const newUrlParts = []
    for (const part of urlParts) {
        if (part.startsWith(':item')) {
            newUrlParts.push(item[part.split('.')[1]])
        } else {
            newUrlParts.push(part)
        }
    }
    return newUrlParts.join('/')
}

function InputItem({ input, data, setData }) {
    const value = data[input.key]

    const prevPreviewValue = input.prevPreviewKey && data[input.prevPreviewKey]

    function handleChange(v, multiItem) {
        const value = input.transformValue ? input.transformValue(v) : v
        const key = multiItem ? `${input.key}_${multiItem}` : input.key
        setData((data) => ({
            ...data,
            [key]: value,
        }))
    }

    const shouldHide = input.shouldHide && input.shouldHide(data)

    const type =
        input.shouldBeText && input.shouldBeText(data) ? 'text' : input.type

    if (shouldHide) return null

    if (input.multiplyFor) {
        return input
            .multiplyFor(data)
            .map((item) => (
                <Input
                    {...input}
                    type={type}
                    prevPreviewValue={prevPreviewValue}
                    value={value}
                    onChange={(v) => handleChange(v, item)}
                    key={`${input.key}_${item}`}
                    inputKey={`${input.key}_${item}`}
                    label={`${input.label}: ${item}`}
                />
            ))
    }

    return (
        <Input
            {...input}
            key={input.key}
            inputKey={input.key}
            type={type}
            prevPreviewValue={prevPreviewValue}
            value={value}
            onChange={handleChange}
        />
    )
}

export default function DataFormNew({
    inputs = [],
    submitText = 'Submit',
    initData = {},
    submitToast = 'Submitted',
    submitNavArg,
    getSubmitMessage,
    initializeAfterSaveFields = {},
    mutationRegexes = [],
    url,
    getUrl,
    sendQuery,
    method = 'POST',
    getBody,
    mapItemToData,
    onSuccess,
    mainButton,
    fetchItemFirst,
    inline,
    getExtraHasChanged,
    saveAndNewButton,
}) {
    const [data, setData] = useState({
        ...inputs.reduce((a, b) => ({ ...a, [b.key]: b.defaultValue }), {}),
        ...initData,
    })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const [message, setMessage] = useState('')
    const navigate = useNavigate()
    const setToast = useToast()
    const mutate = useMatchMutate()
    const [searchParams] = useSearchParams()
    const returnTo = searchParams.get('returnTo')
    const [item, setItem] = useState(null)
    const [itemError, setItemError] = useState(null)
    const [itemLoading, setItemLoading] = useState(false)

    // Only supports primitive fields and primitive arrays
    const [history, setHistory] = useState([{ ...initData }])

    useEffect(function () {
        if (method !== 'PATCH' && !fetchItemFirst) return
        async function fetchItem() {
            setItemLoading(true)
            const { error, responseData } = await fetchAPI(
                getUrl || url,
                null,
                'GET',
            )
            setItemLoading(false)
            if (error) {
                setItemError(error)
                return
            }
            setItem(responseData)
            if (mapItemToData) {
                const newData = mapItemToData(responseData)
                setData({ ...newData })
                setHistory([{ ...newData }])
            }
        }
        fetchItem()
    }, [])

    function initializeAfterSave(allFields) {
        if (allFields) {
            setData({ ...initData })
            window.scrollTo(0, 0)
            return
        }
        const newData = { ...initData, ...data }
        for (const [k, v] of Object.entries(initializeAfterSaveFields)) {
            // TODO: other cases
            newData[k] = v
        }
        setData(newData)
        window.scrollTo(0, 0)
    }

    async function onSubmit(e, resetAndStay) {
        e.preventDefault()
        setError('')

        let body = { ...data }
        if (getBody) {
            try {
                body = await getBody(data, item)
            } catch (error) {
                setError(error.message || 'Unexpected error')
                return
            }
        }

        setLoading(true)
        const fetchUrl = getUrl ? populateUrlVariables(url, item) : url
        const { error, responseData } = await fetchAPI(
            `${fetchUrl.split('?')[0]}${sendQuery ? `${sendQuery}` : ''}`,
            body,
            method,
            body instanceof FormData ? {} : undefined,
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        submitToast !== '' && setToast(submitToast)

        if (getSubmitMessage) {
            const message = getSubmitMessage(responseData)
            if (message) {
                setMessage(message)
            }
        }

        if (returnTo && !resetAndStay) {
            navigate(returnTo)
        } else if (submitNavArg && !resetAndStay) {
            navigate(submitNavArg)
        } else if (
            Object.keys(initializeAfterSaveFields).length ||
            resetAndStay
        ) {
            initializeAfterSave(resetAndStay)
        }
        for (const item of mutationRegexes) {
            mutate(item)
        }

        onSuccess && (await onSuccess(responseData))
    }

    if (itemLoading) {
        return <Spinner />
    }

    if (itemError) {
        return <ErrorMessage>{itemError}</ErrorMessage>
    }

    if ((method === 'PATCH' || fetchItemFirst) && !item && !itemError) {
        return <Spinner />
    }

    const inputComponents = []
    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i]

        if (input.toggleGroup) {
            const prevInput = inputs?.[i - 1]
            if (prevInput.toggleGroup === input.toggleGroup) {
                const prevComponent =
                    inputComponents[inputComponents.length - 1]
                prevComponent.push(input)
            } else {
                inputComponents.push([input])
            }
        } else {
            inputComponents.push(input)
        }
    }

    return (
        <div className="data-form">
            {message && (
                <MessageSection type="success" onDismiss={() => setMessage('')}>
                    <div>{message}</div>
                </MessageSection>
            )}

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <form
                onSubmit={onSubmit}
                className={`data-form${inline ? ' data-form-inline' : ''}`}
            >
                {inputComponents.map((item) =>
                    Array.isArray(item) ? (
                        <Toggle
                            title={item[0].toggleGroup}
                            key={item[0].toggleGroup}
                        >
                            {item.map((innerItem) => (
                                <InputItem
                                    key={innerItem.key}
                                    input={innerItem}
                                    data={data}
                                    setData={setData}
                                />
                            ))}
                        </Toggle>
                    ) : (
                        <InputItem
                            key={item.key}
                            input={item}
                            data={data}
                            setData={setData}
                        />
                    ),
                )}

                <div>
                    {Boolean(saveAndNewButton) && (
                        <Button
                            outline
                            text="Save and create new"
                            onClick={(e) => onSubmit(e, true)}
                            type="button"
                            isLoading={loading}
                        />
                    )}
                    <Button
                        text={submitText}
                        type="submit"
                        isLoading={loading}
                    />
                </div>
                {Boolean(mainButton) && (
                    <MainButton
                        disabled={loading}
                        functionality="SAVE"
                        loading={loading}
                    />
                )}
            </form>
        </div>
    )
}
