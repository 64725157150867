import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'

export default function UserPagesList() {
    const auth = useAuth()

    return (
        <>
            <ResourceList
                paginated
                paginationLimit={200}
                getItemsFromResponse={(data) => data?.results}
                apiUrl={'/pages'}
                initialQuery={{
                    showOnClientMenu: auth.isClient || undefined,
                    showOnTeamMenu: auth.isStaff || undefined,
                }}
                itemClickPath={'/pages/:item.slug'}
                filters={{
                    search: true,
                }}
                fields={[
                    {
                        column: 1,
                        getValue: (item) =>
                            `pages/${item.parentPage ? `${item.parentPage.slug}/` : ''}${item.slug}`,
                        getClassName: () => 'subtitle',
                    },
                    {
                        column: 1,
                        getValue: (item) => item.title,
                        getClassName: () => 'title',
                    },
                ]}
            />
        </>
    )
}
