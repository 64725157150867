import './App.scss'
import { Routes, Route, Outlet, useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import RequireAuth from './common/RequireAuth'
import { useStore } from './common/Context'
import fetchAPI from './common/fetchAPI'
import Modal from './components/common/Modal'
import Toasts from './components/common/Toasts'
import MainHeader from './components/common/MainHeader'
import { SWRConfig } from 'swr'
import routes from './routes'
import Terms from './components/common/Terms'
import NavBar from './components/navigation/NavBar'
import useAuth from './common/hooks/useAuth'
import useScrollAnchor from './common/hooks/useScrollAnchor'
import ScrollTopButton from './components/common/ScrollTopButton'
import DonationModal from './pages/admin/donations/DonationModal'
import Ad from './components/ads/Ad'
import ReactGA from 'react-ga4'
import NotFound from './pages/not-found/NotFound'
import Button from './components/common/Button'
import Spinner from './components/common/Spinner'
import MainFooter from './components/common/MainFooter'

function AdminLayout() {
    return (
        <>
            <Ad />
            <main className="admin-layout">
                <Outlet />
            </main>
            <Terms>
                <Ad />
            </Terms>

            <MainFooter />
        </>
    )
}

function PublicLayout() {
    return (
        <>
            <main className="public-layout">
                <Outlet />
            </main>
            <Terms />
        </>
    )
}

function MixedLayout() {
    const auth = useAuth()
    return auth.user ? <AdminLayout /> : <PublicLayout />
}

function RegistrationLayout() {
    return (
        <>
            <main className="registration-layout">
                <Outlet />
            </main>
            <Terms />
        </>
    )
}

function App() {
    const [state, dispatch] = useStore()
    const location = useLocation()
    const { state: locationState } = location

    useEffect(() => {
        const getUserFromServer = async () => {
            dispatch({ type: 'START_INITIAL_USER_LOAD' })

            const { responseData, status, error } = await fetchAPI(
                `/v1/users/me`,
                {},
                'GET',
            )

            if (error || status !== 200) {
                dispatch({
                    type: 'FINISH_INITIAL_USER_LOAD',
                    payload: null,
                })
                return
            }

            dispatch({
                type: 'FINISH_INITIAL_USER_LOAD',
                payload: responseData,
            })

            ReactGA.set({
                userId: responseData.id,
            })

            const storedTheme = localStorage.getItem('theme')
            if (storedTheme) {
                dispatch({ type: 'SET_THEME', payload: storedTheme })
            }
        }

        getUserFromServer()
    }, [])

    const swrGlobalOptions = {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    }

    useScrollAnchor()

    function handleDevSession() {
        const v =
            localStorage.getItem('use_session_auth') === 'true'
                ? 'false'
                : 'true'
        localStorage.setItem('use_session_auth', v)
    }

    if (state.initLoading) {
        return <Spinner marginTop />
    }

    const usingSessionStorage =
        localStorage.getItem('use_session_auth') === 'true'

    return (
        <SWRConfig value={swrGlobalOptions}>
            <div
                className={`App${state.isModalOpen ? ' modal-open' : ''} theme-${state.theme}`}
            >
                {process.env.NODE_ENV === 'development' && (
                    <>
                        <Button
                            onClick={handleDevSession}
                            text={`SESSION STORAGE (${
                                usingSessionStorage ? 'USING' : 'NOT USING'
                            })`}
                            tiny
                            outline
                        />
                        {/* eslint-disable-next-line react/no-unknown-property */}
                        <style jsx="true">{`
                            .adsbygoogle {
                                display: none !important;
                            }
                        `}</style>
                    </>
                )}
                <Modal />
                <Toasts />
                <ScrollTopButton />
                <DonationModal />
                <Routes>
                    <Route element={<PublicLayout />}>
                        {routes
                            .filter(
                                (item) => item.public && !item.registrationFlow,
                            )
                            .map((item) => (
                                <Route
                                    key={item.url}
                                    path={item.url}
                                    element={item.component}
                                />
                            ))}
                    </Route>
                    <Route element={<MixedLayout />}>
                        {routes
                            .filter((item) => item.mixedAuth)
                            .map((item) => (
                                <Route
                                    key={item.url}
                                    path={item.url}
                                    element={
                                        <>
                                            <MainHeader
                                                backUrl={item.backUrl}
                                            />
                                            <div className={item.className}>
                                                {item.component}
                                            </div>
                                        </>
                                    }
                                />
                            ))}
                    </Route>

                    <Route element={<RegistrationLayout />}>
                        {routes
                            .filter(
                                (item) => item.public && item.registrationFlow,
                            )
                            .map((item) => (
                                <Route
                                    key={item.url}
                                    path={item.url}
                                    element={item.component}
                                />
                            ))}
                    </Route>
                    <Route element={<RegistrationLayout />}>
                        {routes
                            .filter((item) => item.registrationFlow)
                            .map((item) => (
                                <Route
                                    key={item.url}
                                    path={item.url}
                                    element={
                                        <RequireAuth routeItem={item}>
                                            <div className={item.className}>
                                                {item.component}
                                            </div>
                                        </RequireAuth>
                                    }
                                />
                            ))}
                    </Route>
                    <Route element={<AdminLayout />}>
                        {routes
                            .filter(
                                (item) =>
                                    !item.public && !item.registrationFlow,
                            )
                            .map((item) => (
                                <Route
                                    key={item.url}
                                    path={item.url}
                                    element={
                                        <RequireAuth routeItem={item}>
                                            <MainHeader
                                                title={
                                                    locationState?.titleOverride ||
                                                    item.title
                                                }
                                                backUrl={item.backUrl}
                                            />
                                            <div className={item.className}>
                                                {item.component}
                                            </div>
                                        </RequireAuth>
                                    }
                                />
                            ))}
                    </Route>

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </SWRConfig>
    )
}

export default App
