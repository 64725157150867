// v18

import { useEffect, useState, useMemo, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ListLayout from './ListLayout'
import MainButton from './MainButton'
import useData from '../../common/hooks/useData'
import ResourceListItems from './ResourceListItems'
import { parseResourcePath } from '../../common/helpers'
import FilterModalContent from '../common/FilterModalContent'
import useModal from '../../common/hooks/useModal'
import useFilter from '../../common/hooks/useFilter'
import ResourceListSelectedItems from './ResourceListSelectedItems'
import ResourceListTableColumns from './ResourceListTableColumns'
import ResourceListPagination from './ResourceListPagination'
import ResourceListTabFilters from './ResourceListTabFilters'
import ResourceListFilters from './ResourceListFilters'
import ResourceListTitle from './ResourceListTitle'
import ResourceListAddButton from './ResourceListAddButton'
import ResourceListItemViewsToggle from './ResourceListItemViewsToggle'
import Button from '../common/Button'
import ErrorMessage from '../common/ErrorMessage'
import SectionContainer from '../common/SectionContainer'

const initialCursorOpts = {
    current: {
        after: '',
        before: '',
    },
    following: {
        after: '',
        before: '',
    },
}

function ResourceList({
    // Data from parent
    items: propItems,
    itemsLoading: propItemsLoading = null,

    // Data from URL
    apiUrl,
    initialQuery,
    getItemsFromResponse,
    mapItems,
    filterItems,

    // Paths to items
    newItemPath,
    itemClickPath,
    onItemClick,

    // General UI
    title,
    subtitle,
    smallTitle = false,
    renderMainButton = false,
    newItemLabel,
    newItemBtnText,
    emptyText,
    hideCount,
    minimal,
    searchOnly,
    flushSpaces,
    searchPlaceholder,
    getIncludedHtml,

    // Item display
    fields,
    itemViews = ['list'],

    // Item actions
    actions,

    // Filters
    filters,
    hideFilterTags = false,

    // Selectable
    isSelectable = false,
    selectedIdField = 'id',
    preselectedIds,
    selectLimit,
    onSelectedItemsChange,
    onSingleItemSelect,
    getSelectedItemText,
    apiRequestSelectedItems = true,

    // Events
    onItemsLoaded,
    onResponseLoaded,

    // Pagination
    paginated,
    loadMoreButton,
    cursorPagination,
    paginationLimit,
    itemsNav,

    // Table
    isTable = false,
    tableColumns,
}) {
    /**
     * Hooks
     */

    const allParams = useParams()
    const [selectedIds, setSelectedIds] = useState(
        selectedIdField ? preselectedIds || [] : null,
    )
    const navigate = useNavigate()
    const { setModal } = useModal()

    const [page, setPage] = useState(paginated && !cursorPagination ? 1 : null)
    const [itemsView, setItemsView] = useState(itemViews?.[0] || 'list')
    const [totalPages, setTotalPages] = useState(
        paginated && !cursorPagination ? 1 : null,
    )
    const [totalResults, setTotalResults] = useState(null)
    const [cursorOpts, setCursorOpts] = useState(
        paginated && cursorPagination ? initialCursorOpts : null,
    )
    const ref = useRef(undefined)

    /**
     * Filtering
     */

    const {
        filterQueryUrlObj,
        currFilter,
        currSort,
        currTabFilterKey,
        filterSetters,
        hasActiveFilter,
        currQueryStr,
    } = useFilter(filters)

    /**
     * Data fetching
     */

    // Includes all items from infinite pagination,
    // or current items if no pagination or cursor is used
    const [allItems, setAllItems] = useState(propItems || [])

    // Every time a page or filter changes,
    // output a new request URL
    const [url, stripPaginateUrl] = useMemo(
        function () {
            if (propItems) return [null, null]
            let url = `/v1${parseResourcePath(apiUrl, allParams)}`
            if (url.includes('?') || url.includes('&')) {
                throw new Error('Invalid url:' + url)
            }
            const queryObj = {}

            if (initialQuery) {
                const definedInitialQuery = Object.entries(initialQuery)
                    .filter(([_k, v]) => typeof v !== 'undefined')
                    .reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {})
                Object.assign(queryObj, definedInitialQuery)
            }
            if (paginated) {
                queryObj.limit = paginationLimit || 20
                if (cursorPagination) {
                    if (cursorOpts.current.after) {
                        queryObj.after = cursorOpts.current.after
                    } else if (cursorOpts.current.before) {
                        queryObj.before = cursorOpts.current.before
                    }
                } else {
                    queryObj.page = page
                }
            }
            Object.assign(queryObj, filterQueryUrlObj)

            const stripPaginateQueryObj = { ...queryObj }
            let stripPaginateUrl = url
            delete stripPaginateQueryObj.page
            delete stripPaginateQueryObj.limit
            delete stripPaginateQueryObj.after
            delete stripPaginateQueryObj.before

            const query = Object.keys(queryObj).reduce(
                (a, b) => `${a !== '' ? `${a}&` : ''}${b}=${queryObj[b]}`,
                '',
            )
            const stripPaginateQuery = Object.keys(
                stripPaginateQueryObj,
            ).reduce(
                (a, b) =>
                    `${a !== '' ? `${a}&` : ''}${b}=${
                        stripPaginateQueryObj[b]
                    }`,
                '',
            )

            if (query.length) {
                url += `?${query}`
            }

            if (stripPaginateQuery.length) {
                stripPaginateUrl += `?${stripPaginateQuery}`
            }

            return [url, stripPaginateUrl]
        },
        [page, JSON.stringify(filterQueryUrlObj), cursorOpts?.current],
    )

    // Currently only sets secondary info,
    // total pages, results, and cursor data if applicable
    function onSuccess(receivedData) {
        const newItems = getItemsFromResponse
            ? getItemsFromResponse(receivedData)
            : receivedData

        if (paginated) {
            if (!cursorPagination) {
                setAllItems((currAllItems) => {
                    if (page === 1) return newItems
                    return [...currAllItems, ...newItems]
                })

                const totalPagesChanged = totalPages !== receivedData.totalPages
                if (totalPagesChanged) {
                    setTotalPages(receivedData.totalPages)
                }
                setTotalResults(receivedData.totalResults)
            } else {
                setCursorOpts({
                    ...cursorOpts,
                    following: {
                        after: receivedData.nextCursor || '',
                        before: receivedData.prevCursor || '',
                    },
                })
                setAllItems(newItems)
            }
        } else if (!propItems) {
            setAllItems(newItems)
        }

        onItemsLoaded && onItemsLoaded(newItems)
        onResponseLoaded && onResponseLoaded(receivedData)
    }

    // Main fetch hook
    const { items, itemsError, itemsLoading, itemsValidating } = useData(
        apiUrl ? url : null,
        'items',
        extractItems,
        false,
        false,
        onSuccess,
        propItems,
    )

    // Reset page to 1 when filters change (if plain paginated)
    useEffect(
        function () {
            if (!paginated || cursorPagination) return
            setPage(1)
        },
        [JSON.stringify(filterQueryUrlObj)],
    )

    // Used in useData to extract items from response
    function extractItems(data) {
        if (!data) return []
        let result = data
        getItemsFromResponse && (result = getItemsFromResponse(data) || [])
        mapItems && (result = result.map((item) => mapItems(item, data)))
        filterItems &&
            (result = result.filter((item) => filterItems(item, data)))

        return result
    }

    /**
     * Selection
     */

    let selectedItemsUrl = null
    if (stripPaginateUrl && selectedIds?.length && apiRequestSelectedItems) {
        selectedItemsUrl = ''
        selectedItemsUrl += stripPaginateUrl.includes('?')
            ? `${stripPaginateUrl}&`
            : `${stripPaginateUrl}?`
        // : `${url}?`
        selectedItemsUrl += `${selectedIdField}=${selectedIds.join(',')}`
    }

    const {
        selectedItemsData,
        selectedItemsDataError,
        selectedItemsDataLoading,
    } = useData(
        selectedItemsUrl,
        'selectedItemsData',
        (data) => data?.results || [],
    )

    const selectedPropItems = propItems?.filter((item) =>
        selectedIds.includes(item[selectedIdField]),
    )

    useEffect(
        function () {
            if (!preselectedIds?.length) return
            setSelectedIds(preselectedIds)
        },
        [(preselectedIds || []).join(',')],
    )

    function onItemSelect(item) {
        if (selectLimit === 1 && selectedIdField) {
            const newSelectedIds = selectedIds.includes(item[selectedIdField])
                ? []
                : [item[selectedIdField]]
            setSelectedIds(newSelectedIds)
            onSelectedItemsChange && onSelectedItemsChange(newSelectedIds)
            onSingleItemSelect && onSingleItemSelect(item)
            return
        }

        const newSelectedIds = selectedIds.includes(item[selectedIdField])
            ? selectedIds.filter((id) => id !== item[selectedIdField])
            : [...selectedIds, item[selectedIdField]]

        setSelectedIds(newSelectedIds)
        onSelectedItemsChange && onSelectedItemsChange(newSelectedIds)
    }

    /**
     * Infinite scrolling
     */

    const [isAtBottom, setIsAtBottom] = useState(false)

    function handleScroll() {
        const listEl = ref?.current
        if (!listEl) return
        const listEnd = listEl.offsetTop + listEl.offsetHeight
        const windowScroll = window.scrollY
        const windowHeight = window.innerHeight
        setIsAtBottom(listEnd - windowScroll - windowHeight - 100 < 0)
    }

    useEffect(function () {
        if (cursorPagination || !paginated || loadMoreButton) return
        window.addEventListener('scroll', handleScroll)
        return () => {
            return window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // Increase page when screen reaches bottom
    useEffect(
        function () {
            if (
                isAtBottom &&
                !itemsLoading &&
                !itemsError &&
                !cursorPagination &&
                !loadMoreButton &&
                paginated &&
                page !== totalPages
            ) {
                setPage(page + 1)
            }
        },
        [isAtBottom],
    )

    /**
     * Filter actions
     */

    function onToggleSort() {
        if (paginated) {
            if (cursorPagination) {
                setCursorOpts(initialCursorOpts)
            } else {
                setAllItems([])
                setPage(1)
            }
        }
        filterSetters.setIsAsc(!currSort.isAsc)
    }

    function onSearchQuery(text) {
        filterSetters.setQueryStr(text)
    }

    function onFilterSubmit(data) {
        const { sortKey, filterValues, clearPrev } = data
        if (typeof sortKey !== 'undefined' && sortKey !== currSort.key) {
            filterSetters.setIsAsc(true)
            filterSetters.setSortKey(sortKey)
        }
        const newFilterValues = {}
        for (const key of Object.keys(filterValues)) {
            if (filterValues[key] === currFilter[key] && !clearPrev) continue
            newFilterValues[key] = filterValues[key]
        }
        filterSetters.setFilter(newFilterValues, clearPrev)
        if (paginated) {
            if (cursorPagination) {
                setCursorOpts(initialCursorOpts)
            } else {
                setAllItems([])
                setPage(1)
            }
        }
        setModal(null)
    }

    function onSortSubmit(data) {
        const { sortKey } = data
        if (typeof sortKey !== 'undefined' && sortKey !== currSort.key) {
            filterSetters.setIsAsc(true)
            filterSetters.setSortKey(sortKey)
        }
    }

    function onFilterDismiss(key) {
        const filter = filters?.filters?.[key]
        if (!filter) return
        const option = filter.options.find((o) =>
            ['undefined', undefined, 'null', null].includes(o.value),
        )
        if (!option) return
        filterSetters.setFilter({ [key]: option.value })
        if (paginated) {
            if (cursorPagination) {
                setCursorOpts(initialCursorOpts)
            } else {
                setAllItems([])
                setPage(1)
            }
        }
    }

    function onFiltersModalOpen() {
        if (!filters) return
        setModal(
            <FilterModalContent
                onSubmit={onFilterSubmit}
                filters={filters}
                currFilter={currFilter}
                currSort={currSort}
            />,
            'Filters',
            'modal-full',
        )
    }

    // Applicable to cursor pagination only
    function onPageNav(delta) {
        if (delta === -1) {
            setCursorOpts({
                ...cursorOpts,
                current: {
                    after: '',
                    before: cursorOpts.following.before,
                },
            })
        }
        if (delta === 1) {
            setCursorOpts({
                ...cursorOpts,
                current: {
                    after: cursorOpts.following.after,
                    before: '',
                },
            })
        }
    }

    /**
     * Render
     */

    const loading =
        (itemsLoading || propItemsLoading) &&
        !(searchOnly && url.includes('@@@@@@@@@@@'))

    const newItemUrl = newItemPath && parseResourcePath(newItemPath, allParams)

    const hasSearch = filters?.search

    function onDeselectItem(item) {
        const newSelectedIds = selectedIds.filter(
            (id) => id !== item[selectedIdField],
        )
        setSelectedIds(newSelectedIds)
        onSelectedItemsChange && onSelectedItemsChange(newSelectedIds)
        onSingleItemSelect && onSingleItemSelect(null)
    }

    let className = 'resource-list'
    if (filters?.length && items.length) className += ' has-filters'
    if (hasSearch) className += ' has-search'
    if (isTable) className += ' has-table'
    if (isSelectable) className += ' has-selections'
    if (minimal) className += ' minimal'
    if (searchOnly) className += ' search-only'
    if (flushSpaces) className += ' flush-spaces'
    if (allItems?.length) className += ' has-results'
    if (itemsValidating && !loading) className += ' is-validating'

    return (
        <>
            <div ref={ref} className={className}>
                <header className="list-header">
                    {isSelectable && (
                        <ResourceListSelectedItems
                            loading={
                                propItemsLoading || selectedItemsDataLoading
                            }
                            error={selectedItemsDataError}
                            items={selectedPropItems || selectedItemsData}
                            removeItem={onDeselectItem}
                            getItemText={getSelectedItemText}
                        />
                    )}

                    {Boolean(title) && (
                        <ResourceListTitle
                            title={title}
                            subtitle={subtitle}
                            smallTitle={smallTitle}
                        />
                    )}

                    {Boolean(newItemUrl && !renderMainButton) && (
                        <ResourceListAddButton
                            newItemBtnUrl={newItemUrl}
                            newItemBtnText={newItemBtnText}
                            loading={loading}
                        />
                    )}

                    {Boolean(currTabFilterKey || filters?.filterCombos) && (
                        <ResourceListTabFilters
                            filters={filters}
                            filterCombos={filters?.filterCombos}
                            currFilter={currFilter}
                            onSubmit={onFilterSubmit}
                            tabFilterKey={currTabFilterKey}
                        />
                    )}

                    {Boolean(
                        filters?.search ||
                            filters?.sort ||
                            Object.values(filters?.filters || [])?.some(
                                (f) => !f.tabs,
                            ),
                    ) && (
                        <ResourceListFilters
                            searchPlaceholder={searchPlaceholder}
                            hideFilterTags={hideFilterTags}
                            filters={filters}
                            currSort={currSort}
                            setSearchQuery={onSearchQuery}
                            toggleSort={onToggleSort}
                            openFilters={onFiltersModalOpen}
                            hasActiveFilter={hasActiveFilter}
                            currFilter={currFilter}
                            onFilterDismiss={onFilterDismiss}
                            onSortSubmit={onSortSubmit}
                        />
                    )}

                    {(page !== null ||
                        cursorOpts?.following?.before ||
                        cursorOpts?.following?.after) && (
                        <ResourceListPagination
                            hasCursorPagination={
                                cursorOpts?.following?.after ||
                                cursorOpts?.following?.before
                            }
                            page={page}
                            count={hideCount ? null : totalResults}
                            pages={totalPages}
                            hasPrevCursor={cursorOpts?.following?.before}
                            hasNextCursor={cursorOpts?.following?.after}
                            onNav={onPageNav}
                            hasEmptyText={Boolean(emptyText)}
                        />
                    )}

                    {itemViews?.length > 1 && (
                        <ResourceListItemViewsToggle
                            itemsView={itemsView}
                            setItemsView={setItemsView}
                            itemViews={itemViews}
                        />
                    )}
                </header>

                {itemsError && (
                    <ErrorMessage section>{itemsError}</ErrorMessage>
                )}

                {getIncludedHtml && !currQueryStr && (
                    <SectionContainer>{getIncludedHtml()}</SectionContainer>
                )}

                <ListLayout
                    itemsView={itemsView}
                    hasWidths={isTable && tableColumns?.some((c) => c.width)}
                    loading={loading}
                >
                    {isTable && !loading && (
                        <>
                            <ResourceListTableColumns
                                tableColumns={tableColumns}
                            />
                        </>
                    )}

                    <ResourceListItems
                        items={allItems}
                        // items={items}
                        itemView={itemsView}
                        fields={fields}
                        itemClickPath={itemClickPath}
                        itemsNavSearch={itemsNav ? url : ''}
                        onItemClick={onItemClick}
                        onItemSelect={isSelectable ? onItemSelect : null}
                        selectedIds={selectedIds}
                        selectedIdField={selectedIdField}
                        actions={actions}
                        filterQuery={currFilter}
                    />

                    {!loading && !itemsValidating && !allItems.length
                        ? // {!loading && !items.length
                          typeof emptyText !== 'undefined'
                            ? emptyText
                            : 'No data'
                        : null}

                    {isTable && !loading && (
                        <div className="list-table-footer">
                            {tableColumns.map((column) => (
                                <span
                                    style={{
                                        flexBasis: column.width
                                            ? `${column.width}%`
                                            : undefined,
                                    }}
                                    key={column.title}
                                >
                                    {column.getSum
                                        ? column.getSum(allItems)
                                        : // ? column.getSum(items)
                                          column.sumTitle || null}
                                </span>
                            ))}
                        </div>
                    )}
                </ListLayout>

                {paginated &&
                    loadMoreButton &&
                    !(!cursorPagination && totalResults <= allItems.length) &&
                    (propItems
                        ? propItems.length !== 0
                        : items?.length !== 0) && (
                        <div className="load-more-button">
                            <Button
                                fullWidth
                                disabled={loading}
                                onClick={() => {
                                    if (loading) return
                                    setPage(page + 1)
                                }}
                                text="Load more"
                                small
                                outline
                            />
                        </div>
                    )}
            </div>

            {newItemUrl && renderMainButton && (
                <MainButton
                    disabled={loading}
                    label={newItemLabel}
                    functionality="CREATE"
                    onClick={() => {
                        if (loading) return
                        navigate(parseResourcePath(newItemUrl, allParams))
                    }}
                />
            )}
        </>
    )
}

export default ResourceList
