import authStorage from './authStorage'
import { API_URL } from './constants'

async function refreshAccessToken() {
    const refreshToken = authStorage.getItem('refresh_token')
    const response = await fetch(API_URL + `/v1/auth/refresh-tokens`, {
        method: 'POST',
        body: JSON.stringify({
            refreshToken: refreshToken,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
    const data = await response.json()
    const { access, refresh } = data
    authStorage.setItem('access_token', access.token)
    authStorage.setItem('refresh_token', refresh.token)
    return access.token
}

async function swrFetcher(url) {
    const opts = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + authStorage.getItem('access_token'),
        },
    }

    let response = await fetch(API_URL + url, opts)

    const isJSON = response.headers
        .get('content-type')
        ?.includes('application/json')

    if (!isJSON) throw new Error('Unknown error: invalid JSON')
    let data = await response.json()

    if (!response.ok && response.status === 401) {
        let canRefreshToken = true
        if (data.message !== 'Please authenticate') canRefreshToken = false
        const refreshToken = authStorage.getItem('refresh_token')
        if (!refreshToken) canRefreshToken = false
        if (!canRefreshToken) throw new Error('Unauthenticated')

        const newToken = await refreshAccessToken()
        opts.headers['Authorization'] = `Bearer ${newToken}`
        response = await fetch(API_URL + url, opts)
        const isJSON = response.headers
            .get('content-type')
            ?.includes('application/json')

        if (!isJSON) throw new Error('Unknown error: invalid JSON')
        data = await response.json()
    } else if (!response.ok) {
        const error = new Error(
            data.message || `Unknown error ${response.status}`,
        )
        error.status = response.status
        throw error
    }

    return data
}

export default swrFetcher
